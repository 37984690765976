<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class=" campaign-detail"
    @primaryClick="startSpring"
    modal-size="lg"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/puzzle.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center second-font header">
        <!-- {{ $t("dashboard-end-camp-modal-title") }} -->
        Bazı Bilgileri Eksik Girdiniz.
      </h4>
      <p class="gray mb-0 text-center">
        <!-- {{ $t("dashboard-end-camp-modal-desc") }} -->
        Bu kampanyaya kişisel bilgileriniz olmadan destek olacaksınız.
      </p>
      <action-buttons
        @open="addToBasket"
        @close="cancel"
        close-bt-label="Geri Dön"
        open-bt-label="Destek Olmaya Devam Et"
        open-bt-class="bt-success"
        open-bt-icon="badge-check"
      />
    </template>
  </el-modal>
</template>

<script>
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox";
import ActionButtons from "@/components/UI/ActionButtons";
import { useRouter } from "vue-router";
import { modalStore } from "@/store/modules/modals";
import ApiService from "@/services/ApiService";
import { Endpoint } from "@/core/network/Endpoint";
import { Endpoints } from "@/core/network/Endpoints";
import { addBasket } from "@/helpers/BasketActions";
import { ProjectTypeEnum } from "@/store/enums/ProjectTypeEnum";
import { ref } from "vue";
import { userStore } from "@/store/modules/UserStore";

export default {
  components: {
    ElModal,
    Checkbox,
    ActionButtons,
  },
  setup() {
    const storeUser = userStore;
    const store = modalStore;
    const router = useRouter();
    const donationData = ref(store.getters.getActiveModalData);
    const cancel = () => {
      store.commit("closeModal");
    };
    const addToBasket = async () => {
      await addBasket(
        donationData.value.id,
        donationData.value.type,
        donationData.value.amount,
        storeUser.getters.getUser?.id,
        donationData.value.name,
        donationData.value.phone
      );
      cancel()
    };
    return { cancel, addToBasket };
  },
};
</script>

<style lang="scss" scoped></style>
