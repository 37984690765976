<template>
  <component :is="$store.getters.getActiveModal"></component>
</template>

<script>
import { defineComponent } from "vue";
import DonateInfoModal from "@/components/Modals/ModalTypes/DonateInfoModal";
import CampaignDeleteModal from "@/components/Modals/ModalTypes/CampaignDeleteModal";
import EndOfCampaignModal from "@/components/Modals/ModalTypes/EndOfCampaignModal";
import MissingInformationModal from "@/components/Modals/ModalTypes/MissingInformationModal";
import DonateNoteModal from "@/components/Modals/ModalTypes/DonateNoteModal";
import DeleteAccountModal from "@/components/Modals/ModalTypes/DeleteAccountModal";
import VerifyModal from "@/components/Modals/ModalTypes/VerifyModal";
import VerifiedModal from "@/components/Modals/ModalTypes/VerifiedModal";
import FastDonateModal from "@/components/Modals/ModalTypes/FastDonateModal";
import ThanksModal from "@/components/Modals/ModalTypes/ThanksModal";
import QuickDonateModal from "@/components/Modals/ModalTypes/QuickDonateModal";
import SacrificeModal from "@/components/Modals/ModalTypes/SacrificeModal";
import SpringModal from "@/components/Modals/ModalTypes/SpringModal";
import ValidationErrorsModal from "@/components/Modals/ModalTypes/ValidationErrorsModal.vue";
import PaymentThreeSecureModal from "@/components/Modals/ModalTypes/PaymentThreeSecureModal.vue";
export default defineComponent({
  name: "ModalContainer",
  components: {
    DonateInfoModal,
    CampaignDeleteModal,
    EndOfCampaignModal,
    MissingInformationModal,
    DonateNoteModal,
    DeleteAccountModal,
    VerifyModal,
    VerifiedModal,
    FastDonateModal,
    ThanksModal,
    QuickDonateModal,
    SacrificeModal,
    SpringModal,
    ValidationErrorsModal,
    PaymentThreeSecureModal
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
