<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class=" code-inputs"
    modal-size="lg"

  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img v-if="!status" :src="require('@/assets/images/retry.png')" alt="" />

        <img v-if="status" :src="require('@/assets/images/check.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center header" v-if="!title">
        {{ $t("dashboard-verf-success-title") }}
      </h4>
      <h4 class="text-center header" v-if="title">
        {{ title }}
      </h4>
      <p class="gray mb-0 text-center" v-if="!description">
        {{ $t("dashboard-verf-success-desc") }}
      </p>
      <p class="gray mb-0 text-center" v-if="description">
        {{ description }}
      </p>
      <div class="gray mb-0 text-center" v-if="autoClose">
         {{Math.round(autoCloseIntervalValue / 5) }} saniye içerisinde otomatik kapanacak.
        <div class="auto-closebar" :style="{width: (autoCloseIntervalValue / 30  )* 100    + '%'}"></div>
      </div>

      <button-el
        type="button"
        v-if="showConfirmButton"
        :label="$t('continue-button')"
        icon="angles-right"
        btn-class="bt-danger"
        @click=""
      />
    </template>
  </el-modal>
</template>

<script lang="ts">
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox.vue";
import ActionButtons from "@/components/UI/ActionButtons.vue";
import CodeInputs from "@/components/Auth/CodeInputs.vue";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import { useRouter } from "vue-router";
import { modalStore } from "@/store/modules/modals";
import { ref } from "vue";
import Chart from "@/components/UI/Chart.vue";

export default {
  components: {
    Chart,
    ElModal,
    Checkbox,
    ActionButtons,
    CodeInputs,
    ButtonEl,
  },
  props:{
    showConfirmButton:{
      type:Boolean,
      default:true
    },
    title:{
      type:String,
      required:false,
    },
    description:{
      type:String,
      required:false,
    },
    status:{
      type:Boolean,
      default:true
    },
    autoClose:{
      type:Boolean,
      default:false
    }
  },
  setup(props : any,context:any) {
    const router = useRouter();
    const autoCloseIntervalValue = ref(30);
    if(modalStore.getters.getAutoClose || props.autoClose){
      const autoCloseInterval = setInterval(()=> {
        autoCloseIntervalValue.value--;
        if(autoCloseIntervalValue.value == 0){
          context.emit('close')
          modalStore.commit("closeModal")
          clearInterval(autoCloseInterval)
        }
      },200);
    }
    const nextStep = () => {};

    return { nextStep , autoCloseIntervalValue };
  },
};
</script>

<style lang="scss" scoped>
.auto-closebar{
  height: 4px;
  background-color: $primary;
  border-radius: 5px ;
  margin-top: 5px;
  transition: .55s ease-in-out;
}
</style>
