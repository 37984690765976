import ApiService from "@/services/ApiService";
import {Endpoint} from "@/core/network/Endpoint";
import {Endpoints} from "@/core/network/Endpoints";

import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import apiService from "@/services/ApiService";
import { getToken } from "@/helpers/TokenActions";

export interface State {
  basketItems?: any,
  loading : Boolean,
  totalPrice : Number,
}

export const key: InjectionKey<Store<State>> = Symbol()

export const basketStore = createStore<State>({
  state: {
    basketItems: [],
    loading : true,
    totalPrice: 0
  },
  getters: {
    getBasketItems(state:any) {
      return state.basketItems;
    },
    getLoading(state:any){
      return state.loading;
    },
    getTotalPrice(state:any){
      return state.totalPrice;
    }
  },
  mutations: {
    setBasketItems(state:any, payload:any) {
      state.basketItems = payload.basket
      state.totalPrice = payload.total_price
    },
    setLoading(state:any,loading:Boolean){
      state.loading = loading;
    },
    setTotalPrice(state:any,price: Number){
      state.totalPrice = price;
    }
  },
  actions: {
    async getBasketItems(state:any) {
      basketStore.commit('setLoading', true);
      try{
        const res = await apiService.M2MClient.get(Endpoints.BASKETS, {
          params: {
            session_token: getToken()
          }
        })
        const data:any = res.data
        basketStore.commit('setBasketItems', res.data);
      }catch (e){
        console.log(e)
      }
      basketStore.commit('setLoading', false);

    },
    async clearBasketItems(state:any) {
      if(basketStore.getters.getBasketItems.length == 0){
        return ;
      }
      basketStore.commit('setLoading',true)
      try{
        const res = await ApiService.M2MClient.delete(Endpoints.BASKET_BULK, {
          params: {
            session_token: getToken()
          }
        });
        basketStore.commit('setBasketItems', {basket:[],total_price:0});
      }catch (e){

      }
      basketStore.commit('setLoading',false)

    },
  }
})

// define your own `useStore` composition function
export function useStore () {
  return baseUseStore(key)
}