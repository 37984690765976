<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class="named-water-donate-modal"
    modal-size="lg"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/smile.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <div class="header-wrapper">
        <h4 class="text-center header">{{$t("waterwell.fast_donate_title")}}</h4>
        <p class="gray mb-0 text-center">
          {{$t("waterwell.fast_donate_description")}}
        </p>
        <ul class="nav nav-pills radio-buttons" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active custom-bt"
              id="button-1"
              data-bs-toggle="pill"
              data-bs-target="#tab-1"
              role="tab"
              @click="handleTypeChange(0)"

              type="button"
              aria-controls="standard-tab"
              aria-selected="true"
            >
              {{$t("waterwell.for_me")}}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link custom-bt"
              id="button-2"
              data-bs-toggle="pill"
              data-bs-target="#tab-1"
              @click="handleTypeChange(1)"
              role="tab"
              type="button"
              aria-controls="single-tab"
              aria-selected="true"
            >
              {{$t("waterwell.for_other")}}
            </button>
          </li>
        </ul>
      </div>
      <div class="footer">
        <div class="content-wrapper">
          <div class="tab-content" id="content">
            <div
              class="tab-pane fade active show"
              id="tab-1"
              role="tabpanel"
              aria-labelledby="button-standard-tab"
            >
              <div class="inputs-line">
                <custom-drop-down :is-fluid="true" @selectAction="handleShareChange"  :options="modalOptions" v-if="modalOptions.length>0" :height="56" />
                <div class="total-wrapper">
                  <p class="btn-text gray">{{$t("waterwell.amount")}}</p>
                  <p class="btn-text dark">₺{{shareCount * parseFloat(modalData.etap.price_per_share)}}</p>
                </div>
              </div>
              <a v-if="userStore.getters.getUser !== null" href="#" @click="handleGetProfileInformation"
                 class="primary pt-3 btn-text text-end"
              >{{$t("waterwell.get_profile_information")}}</a>
              <p class="gray span-text">
                {{$t("waterwell.donater_name")}}
              </p>
              <div class="inputs-line">
                <input-wrapper v-model="name" type="text" :placeholder='$t("waterwell.add_name")' />
                <input-wrapper v-model="phone" type="numeric" :placeholder='$t("waterwell.add_phone")' />
              </div>
            </div>
          </div>
        </div>
        <button-el class="bt bt-bg-primary"  :loading="loading" @click="handleSupport" btn-class="" :label='$t("waterwell.make_donate")'>

        </button-el>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { onMounted, ref } from "vue";
import ElModal from "@/components/UI/Modal.vue";
import InputWrapper from "@/components/UI/InputWrapper.vue";
import { ModalEnum } from "@/store/enums/ModalEnum";
import { ModalPayloadInterface } from "@/store/interfaces/ModalPayloadInterface";
import { useStore } from "vuex";
import CustomDropDown from "@/components/Form/CustomDropDown.vue";
import { modalStore } from "@/store/modules/modals";
import { userStore } from "@/store/modules/UserStore";
import { useI18n } from "vue-i18n";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import {addBasket} from "@/helpers/BasketActions";
import {ProjectTypeEnum} from "@/store/enums/ProjectTypeEnum";

export default {
  computed: {
    userStore() {
      return userStore;
    }
  },
  components: {
    ButtonEl,
    CustomDropDown,
    ElModal,
    InputWrapper
  },
  setup() {
    const store = modalStore;
    const name = ref("");
    const phone = ref("");
    const type = ref(0)
    const modalOptions = ref([

    ])
    const shareCount = ref(1)
    const loading = ref(false)
    const {t} = useI18n()
    const modalData = modalStore.getters.getActiveModalData
    const handleShareChange = (e:any) => {
      shareCount.value = e;
    }
    const handleTypeChange = (e:any) => {
      type.value = e
    }
    onMounted(() => {
      for (let i = 1; i < 6; i++) {
        // @ts-ignore
        modalOptions.value.push({label: `${i} ` + (t("waterwell.person")), value: i })
      }
    })

    const handleSupport = async () => {
      loading.value = true
      await addBasket(modalData.etap.id, ProjectTypeEnum.WaterWell, parseFloat(modalData.etap.price_per_share) * shareCount.value, userStore.getters.getUser?.id, name.value, phone.value,shareCount.value )
      name.value = ''
      phone.value = ''
      setTimeout(()=> loading.value = false,200)
      store.commit("closeModal", "codeInputsModal");
    };

    const handleGetProfileInformation = (e: any) => {
      e.preventDefault();
      if (userStore.getters.getUser !== null) {
        name.value = userStore.getters.getUser.first_name + " " + userStore.getters.getUser.last_name;
        phone.value = userStore.getters.getUser.phone;
      }
    };
    return { handleSupport, modalOptions, name, phone, handleGetProfileInformation, modalData, type ,loading,handleShareChange,shareCount,handleTypeChange};
  }
};
</script>

<style lang="scss"></style>
