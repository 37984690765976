<template>
  <transition name="modal-fade">
    <div class="modal-wrapper" :class="[modalWrapperClass, isMobile ? 'xs' : 'lg']">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header class="modal__header" id="modalTitle">
          <slot name="header" class="modal__header"> </slot>
        </header>
        <button-el @click="close" @handle-click="$emit('close')" btn-class="bt-white bt-close bold" icon="xmark"
          :label="$t('dashboard-close-button')" aria-label="Close modal" />

        <section id="modalDescription" class="modal-body w-100" :class="$props.bodyClass">
          <slot name="body"> </slot>
        </section>

        <button-el v-if="btnClass" :disabled="disabled"  :btn-class="btnClass" type="button" :icon="btnIcon" :label="btnLabel"
          aria-label="Close modal" @click="$emit('primaryClick')" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed } from "vue";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import { useStore } from "vuex";
import { modalStore } from "@/store/modules/modals";

export default {
  name: "Modal",
  components: { ButtonEl },
  props: {
    btnClass: {
      type: String,
      required: false,
    },
    btnIcon: {
      type: String,
      required: false,
    },
    btnLabel: {
      type: String,
      required: false,
    },
    modalWrapperClass: {
      type: String,
      required: false,
    },
    modalSize: {
      type: String,
      required: false,
    },
    isScaleble: {
      type: Boolean,
      required: false,
    },
    bodyClass: {
      required: false,
      default: ''
    },
    disabled: {
      required: false,
      default: false,
    }
  },
  emits: ['close', 'primaryClick'],


  setup() {
    const store = modalStore
    const close = () => {
      store.commit("closeModal");
    };
    const isMobile = computed(() => {
      if (window.innerWidth < 774) {
        return true;
      } else {
        return false;
      }
    });
    return {
      close,
      isMobile,
    };
  },
};
</script>

<!--Transition styles-->
<style lang="scss">
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>

<style lang="scss">
.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 11000;
  background: rgba(56, 68, 86, 0.3);
  backdrop-filter: blur(10px);

  &.validationDone {
    .modal {
      .modal__header {
        margin-bottom: 30px;
      }

      .modal-body {
        margin-bottom: 0;
      }
    }
  }

  &.sm .modal {
    width: 360px;
  }

  &.xl .modal {
    width: 80vw;
  }

  &.xs .modal {
    width: 360px;

    .bt {
      width: 70%;
    }

    .bt-close {
      width: 30%;
    }

    .modal-body {
      .action-buttons {
        .bt {
          width: 100%;
        }
      }
    }
  }

  .modal {
    background: $white;
    display: flex;
    height: max-content;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 60px;
    width: 50vw;

    .modal__header {
      margin-bottom: 60px;
      width: 100%;
    }

    .bt-close {
      position: absolute;
      top: 0;
      right: 0;
      backdrop-filter: blur(5px);
      border: 0;
      background: $light;
      transition: $trns;

      span {
        color: $gray;
      }

      svg {
        fill: $gray;
      }

      &:hover {
        background-color: $danger;

        span {
          color: $white;
        }

        svg {
          fill: $white;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      padding: 0 60px;
      //margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 774px) {
    .modal {
      min-width: unset;
      max-width: unset;
      width: 100%;

      .modal-body {
        padding: 0 30px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
