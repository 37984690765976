const profile = [
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Dashboard/Profile/Profile.vue"),
    // redirect: {
    //   name: "profile-dashboard",
    // },
    meta: {
      layout: "DashboardLayout",
      title: "Profilim",
    },

    children: [
      {
        path: "personal-data",
        name: "personal-data",
        component: () =>
          import("@/views/Dashboard/Profile/ProfilePersonalData.vue"),
      },
      {
        path: "profile-options",
        name: "profile-options",
        component: () => import("@/views/Dashboard/Profile/ProfileOptions.vue"),
      },
    ],
  },
  {
    path: "/profile/dashboard",
    name: "profile-dashboard",
    component: () => import("@/views/Dashboard/Panel.vue"),
    meta: {
      title: "Panelim",
      layout: "DashboardLayout",
    },
  },
];

export default profile;
