<template>
  <div v-if="headerStore.getters.yellowHeaderVisible" class="info-header">
    <a href="#" class="abs-link"></a>
    <p class="span-text bold dark mb-0">{{ this.$t("site-topbar-text") }}</p>
    <inline-svg
      @click="headerStore.commit('hideYellowHeader')"
      :src="require('@/assets/images/icons/xmark.svg')"
      fill="#000000"
    />
  </div>
</template>

<script>
import { headerStore } from "@/store/modules/header";

export default {
  name: "YellowHeader",
  computed: {
    headerStore() {
      return headerStore;
    },
  },
  data: () => ({
    visible: true,
  }),
};
</script>

<style lang="scss">
.info-header {
  background: $warning;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
  p {
    color: #000;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.36px;
  }
  svg {
    position: absolute;
    right: 10px;
    z-index: 1000;
    cursor: pointer;
  }
}
</style>
