<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class="water-donate-modal sm"
  >
    <template v-slot:body>
      <div class="block-header">
        <div>
          <p class="title-badge span-text" style="margin-bottom: 15px">
            <inline-svg
              fill="#f36e2a"
              :src="require('@/assets/images/icons/street-view.svg')"
            />
            Çad
          </p>
          <h4 class="" style="margin-bottom: 5px">₺{{ data.total_amount }}</h4>
          <p class="gray mb-0 span-text bold">
            hedeflenen ₺{{ data.goal_amount }}
          </p>
        </div>
        <div class="chart-wrapper">
          <chart
            type="orange-bar"
            bars="single"
            :percent="(data.total_amount / data.goal_amount) * 100"
          />
        </div>
      </div>
      <ul class="nav nav-pills radio-buttons w-100" role="tablist">
        <li class="nav-item flex-grow-1" role="presentation">
          <button
            class="nav-link active custom-bt"
            id="button-5"
            data-bs-toggle="pill"
            data-bs-target="#tab-5"
            role="tab"
            type="button"
            aria-controls="tab-5"
            aria-selected="true"
          >
            Standart
          </button>
        </li>
        <li class="nav-item flex-grow-1" role="presentation">
          <button
            class="nav-link custom-bt"
            id="button-6"
            data-bs-toggle="pill"
            data-bs-target="#tab-6"
            role="tab"
            type="button"
            aria-controls="tab-5"
            aria-selected="true"
          >
            Kişi Bazında
          </button>
        </li>
      </ul>
      <div class="content-wrapper">
        <div class="tab-content" id="content">
          <div
            class="tab-pane fade active show"
            id="tab-5"
            role="tabpanel"
            aria-labelledby="button-standard-tab"
          >
            <div class="inputs-wrapper">
              <price-inputs
                :key="shareValRefresher"
                @selectedVal="catchAmount"
                :amount="data.hasOwnProperty('endPrice') ? data.endPrice : 0"
                :terminate="true"
              />
              <a
                href="#"
                @click="handleGetProfileInformation"
                class="primary btn-text text-end"
                >Profil Bilgilerimi Getir</a
              >
              <input-wrapper
                type="Text"
                v-model="name"
                placeholder="Isim Ekle"
              />
              <input-wrapper
                type="numeric"
                v-model="phone"
                placeholder="Telefon Ekle"
              />
              <button-el
                btn-class="bt-bg-primary w-100"
                label="Destek Ol"
                :is-disabled="amount === undefined"
                @click="nextStep"
              />
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="tab-6"
            role="tabpanel"
            aria-labelledby="button-standard-tab"
          >
            <div class="inputs-wrapper">
              <multi-select2
                :key="shareValRefresher"
                placeholder="Hisse Seçin"
                :options="options"
                @select="calculateAmount"
              />
              <div class="terminate-project w-100">
                <p class="btn-text gray mb-0 medium">Tutar:</p>
                <p class="btn-text dark mb-0 text-left bold">₺{{ amount }}</p>
              </div>
              <div class="input-wrapper">
                <input-wrapper
                  type="Text"
                  v-model="name"
                  placeholder="Isim Ekle"
                />
                <input-wrapper
                  type="numeric"
                  v-model="phone"
                  placeholder="Telefon Ekle"
                />
                <button-el
                  btn-class="bt-bg-primary w-100"
                  label="Destek Ol"
                  :is-disabled="amount === undefined"
                  @click="nextStep"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { onMounted, ref } from "vue";
import ElModal from "@/components/UI/Modal.vue";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import InputWrapper from "@/components/UI/InputWrapper.vue";
import Chart from "@/components/UI/Chart.vue";
import PriceInputs from "@/components/Pages/Base/components/PriceInputs.vue";
import { useRouter } from "vue-router";
import { modalStore } from "@/store/modules/modals";
import { userStore } from "@/store/modules/UserStore";
import { addBasket } from "@/helpers/BasketActions";

export default {
  components: {
    ElModal,
    ButtonEl,
    InputWrapper,
    Chart,
    PriceInputs,
  },
  setup() {
    const store = modalStore;
    const storeUser = userStore;
    const router = useRouter();
    const codeEntered = ref(false);
    const data = ref(store.getters.getActiveModalData);
    const amount = ref();
    const name = ref();
    const phone = ref();
    const options = ref<Array<any>>([]);
    const shareVal = ref();
    const shareValRefresher = ref();
    const loading = ref(false);

    onMounted(async () => {
      for (let i = 1; i < 12; i++) {
        options.value.push({
          label: `${i} Hisse`,
          value: i,
        });
      }
    });

    const clearForm = () => {
      name.value = "";
      phone.value = "";
      amount.value = 0;
    };
    const handleEnd = () => {
      codeEntered.value = true;
    };
    const calculateAmount = (e: any) => {
      amount.value = e * data.value.cost;
    };
    const catchAmount = (e: any) => {
      amount.value = parseInt(e);
    };
    const nextStep = async () => {
      loading.value = true;
      const clearStatus = await addBasket(
        data.value.id,
        data.value.type,
        amount.value,
        storeUser.getters.getUser?.id,
        name.value,
        phone.value,
        shareVal.value ? shareVal.value : 1
      );
      clearStatus && clearForm();
      loading.value = false;
      name.value = "";
      amount.value = "";
      phone.value = "";
      shareValRefresher.value = "";
      data.hasOwnProperty("");
      store.commit("closeModal", "codeInputsModal");
    };
    const endProject = async () => {
      loading.value = true;
      await addBasket(
        data.value.id,
        data.value.type,
        data.value.endPrice,
        storeUser.getters.getUser?.id,
        name.value,
        phone.value
      );
      loading.value = false;
    };
    const handleGetProfileInformation = (e: any) => {
      e.preventDefault();
      if (userStore.getters.getUser !== null) {
        name.value =
          userStore.getters.getUser.first_name +
          " " +
          userStore.getters.getUser.last_name;
        phone.value = userStore.getters.getUser.phone;
      }
    };
    return {
      nextStep,
      codeEntered,
      handleEnd,
      catchAmount,
      name,
      phone,
      endProject,
      options,
      amount,
      calculateAmount,
      data,
      handleGetProfileInformation,
      loading,
      shareValRefresher,
    };
  },
};
</script>

<style lang="scss">
.input-wrapper {
  width: 100% !important;
}

.multiselect-options {
  max-height: unset !important;
}

.swal2-container {
  z-index: 9999999999999999999999999 !important;
}
</style>
