import { translationStore } from "@/store/modules/translationStore";

const isNumber = (evt: any) => {
    if (
        evt.keyCode !== 8 &&
        evt.keyCode !== 96 &&
        evt.keyCode !== 48 &&
        !+evt.key
    ) {
        evt.preventDefault();
    }
};

const getTranslation = (group:any,key:any) : String => {
    let translations :any  = {}
    if (translationStore.getters.translations !== null){
        translations = translationStore.getters.translations
    }else{
        translationStore.dispatch("getTranslations")
        translations = translationStore.getters.translations
    }
    if(translations?.hasOwnProperty(group)){
        if(translations[group].hasOwnProperty(key)){
            return translations[group][key]
        }else{
            return 'NFK';
        }
    }
    return 'NFG';
}

export {isNumber,getTranslation};
