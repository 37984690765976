export default {
  "corporate": {
    
  },
  "global": {
    "navs": {
      "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projelerim"])},
      "water-well": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Kuyuları"])},
      "victim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurban"])},
      "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizi Tanı"])},
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haberler"])},
      "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplar"])},
      "spring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalar"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
      "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaç Kişiyi Suya Kavuşturdum"])},
      "water-wells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim Verdiğim Su Kuyuları"])},
      "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalarım"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilim"])},
      "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış Yap"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Ol"])},
      "donta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Yap"])}
    }
  },
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "campagin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya"])},
  "com-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim Hattı"])},
  "contact-breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize Ulaşabileceğin Seçenekler"])},
  "month-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayda ₺5 gibi düşük bir ücretle bağış yapabilir veya aylık bağış topluluğumuz Kampanya'ya katılabilirsiniz."])},
  "joim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su krizini çözmede Cana katıl."])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to Donate"])},
  "mdl-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteğin bizi çok mutlu etti ama önce hesabına giriş yapman gerekiyor :)"])},
  "mdl-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "end-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeyi Tamamla"])},
  "for-once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek Seferlik"])},
  "organised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenli"])},
  "per-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi bazında"])},
  "form-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim Soyisim"])},
  "form-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Posta Adresi"])},
  "form-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "form-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu"])},
  "form-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajınız"])},
  "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Gönder"])},
  "donate-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Notu"])},
  "select-camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya seç"])},
  "given-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilen isim"])},
  "cancel-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal Et"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajınız"])},
  "etap-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26. Etap Desteklerinizi Bekliyor"])},
  "etap-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "share-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kodunu Paylaş"])},
  "together-we-can": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birlikte, su krizini bitirebiliriz"])},
  "verf-waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama Bekliyor"])},
  "send-verf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Onaya Gönder"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulandı"])},
  "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seni Görmek Çok Güzel"])},
  "login-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon numarası doğrulaması ile hızlı giriş yap."])},
  "login-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap"])},
  "otp-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon Numarası Doğrulaması"])},
  "otp-subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phoneNumber")), " telefon numarasına gönderilen kodu girin."])},
  "wrong-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! Görünen o ki yanlış kod girdin."])},
  "wrong-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden Kod İste"])},
  "time-expired-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! Görünen o ki yanlış kod girdin."])},
  "time-expired-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden Kod İse"])},
  "register-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seni Daha İyi Tanıyalım"])},
  "register-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen kişisel bilgilerini ekle, merak etme iznin olmadan hiç biri kullanılmamaktadır."])},
  "register-pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Resmi"])},
  "register-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
  "register-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyisim"])},
  "register-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Posta"])},
  "register-kvkk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KVKK metnini onaylıyorum."])},
  "register-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Ol"])},
  "kvkk-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KVKK"])},
  "cookie-policy-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çerez Politikası"])},
  "terms-of-use-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Koşulları"])},
  "nav-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projelerimiz"])},
  "nav-about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizi Tanı"])},
  "nav-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haberler"])},
  "nav-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplar"])},
  "nav-the-spring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalar"])},
  "nav-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
  "nav-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap"])},
  "nav-donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Yap"])},
  "nav-projects-drop-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Kuyusu"])},
  "nav-projects-drop-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurban"])},
  "site-topbar-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her bağış yeni bir umut getirir."])},
  "slider-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birlikte <br /> Dünyayı Değiştireceğiz"])},
  "slider-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "slider-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hızlı Bağış"])},
  "slider-card-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek Ol"])},
  "support-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek Ol"])},
  "see-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detayını Gör"])},
  "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devamını Oku"])},
  "slider-card-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık ₺40 bağışınız her yıl 12 kişiye temiz su sağlayabilir."])},
  "general-badge-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NELER BAŞARDIK"])},
  "numbers-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["771 milyon insan temiz ve güvenli içme suyuna temel erişimden yoksundur. Bunu değiştirmek için bir görevdeyiz."])},
  "numbers-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "numbers-item-1-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["91,414"])},
  "numbers-item-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su projelesi finanse edildi"])},
  "numbers-item-2-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14,762,215"])},
  "numbers-item-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsanla hizmet verildi"])},
  "numbers-item-3-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29"])},
  "numbers-item-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
  "numbers-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizi Tanı"])},
  "home-about-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NELER BAŞARDIK"])},
  "home-about-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["771 milyon insan temiz ve güvenli içme suyuna temel erişimden yoksundur. Bunu değiştirmek için bir görevdeyiz."])},
  "home-about-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "home-about-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi Sipariş Ver"])},
  "our-informercial-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YAKINDAN TANI"])},
  "our-informercial-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu gerçek bir hikaye"])},
  "our-informercial-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "badge-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalar"])},
  "project2-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje tanıtımı TR içerik"])},
  "the-spring-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya üyeleri her ay 45.197 kişiye temiz su getiriyor."])},
  "the-spring-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "the-spring-see-al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı Kampanyaları Gör"])},
  "the-spring-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya üyeleri, bir etki yaratmaya ve su krizini sona erdirmeye kendini adamış aylık bağışçılardır."])},
  "the-spring-bottom-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sende Bir Kampanya Başlat"])},
  "the-spring-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor.Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "home-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["771 milyon insan temiz ve güvenli içme suyuna temel erişimden yoksundur. Bunu değiştirmek için bir görevdeyiz."])},
  "home-card-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "home-card-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temuz Su Kazandırır"])},
  "waterwell-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UMUT OL"])},
  "waterwell-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birlikte, yoksulluğun temel nedenleriyle mücadele ederek 200 milyondan fazla savunmasız çocuğun hayatını etkiledik."])},
  "waterwell-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "waterwell-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Kuyusuna İsmini Ver"])},
  "waterwell-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birlikte Dünyayı Değiştireceğiz"])},
  "blog-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HİKAYELER"])},
  "blog-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birde Bizden Dinleyin"])},
  "blog-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "about-us-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birlikte Dünyayı Değiştireceğiz"])},
  "about-us-breadcrumb-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "about-us-breadcrumb-scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağı Kaydır"])},
  "about-card-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HER ŞEY ORTADA"])},
  "about-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözümüz var - ve daha fazlası"])},
  "about-card-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "about-card-item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her Projeyi Kanıtlıyoruz"])},
  "about-card-item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz Açık Bir Kitabız"])},
  "about-card-item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%100 Sahaya Gider"])},
  "about-us-map-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NERELERDEYİZ"])},
  "about-us-map-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birlikte Çok Yere Ulaştık"])},
  "about-us-map-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "about-us-counter-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget\n                fringilla in. Quam mauris in tellus viverra hac diam vel, tellus\n                tortor."])},
  "bank-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Yap"])},
  "bank-breadcrumb-scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağı Kaydır"])},
  "bank-branch-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ŞUBE ADI"])},
  "bank-accound-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HESAP ADI"])},
  "bank-swift-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWIFT KODU"])},
  "bank-accound-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HESAP TÜRÜ"])},
  "bank-account-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HESAP NO"])},
  "bank-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EK NO"])},
  "bank-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "contact-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim Bilgilerimiz"])},
  "contact-social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosyal Medya Hesaplarımız"])},
  "contact-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "contact-form-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MESAJ BIRAK"])},
  "contact-form-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size Nasıl Yardımcı Olabiliriz?"])},
  "contact-form-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "donate-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Yap"])},
  "news-breadcrumb-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haberler"])},
  "news-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Parça Tebessüm İçin Her Şey"])},
  "news-breadcrumb-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "news-page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor.Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "sacrifice-detail-breadcrumb-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacrifice Detay"])},
  "sacrifice-detail-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Parça Tebessüm İçin Her Şey"])},
  "sacrifice-detail-breadcrumb-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "sacrifice-detail-input1-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Hisse"])},
  "sacrifice-detail-input2-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı Miktar (Min. 5)"])},
  "sacrifice-detail-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek Ol"])},
  "waterwell-breadcrumb-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterwell"])},
  "waterwell-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Parça Tebessüm İçin Her Şey"])},
  "waterwell-breadcrumb-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "waterwell-page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor.Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "other-project-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NELER VAR"])},
  "other-project-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer Projelerimiz"])},
  "other-project-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "donate-summary-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Özeti"])},
  "donate-summary-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Your Account Details"])},
  "payment-info-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Bilgileri"])},
  "payment-info-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Your Account Details"])},
  "donate-success-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Tamamlandı"])},
  "donate-success-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Your Account Details"])},
  "payment-basket-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Sepetim"])},
  "payment-clear-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])},
  "payment-total-donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Bağış"])},
  "continue-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam Et"])},
  "login-register-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap / Üye Ol"])},
  "payment-card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Numaranız"])},
  "payment-card-mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AA"])},
  "payment-card-yy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY"])},
  "payment-card-cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
  "payment-card-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Üzerindeki İsim Soyisim"])},
  "payment-accept-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım koşullarını kabul ediyorum."])},
  "payment-save-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartı kaydet ve varsayılan yap."])},
  "donate-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Yap"])},
  "donate-success-title-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir umut olduğun için teşekkürler :)"])},
  "donate-success-desc-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "donate-success-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla Yardımda Bulun"])},
  "footer_rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm hakları saklıdır."])},
  "footer-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayra Vesile, Türk kayıtlı bir yardım kuruluşudur (9128441) ve Türkiye bir kayıtlı şirkettir."])},
  "costs-table-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜLKE"])},
  "costs-table-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TİP"])},
  "costs-table-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MESAFE"])},
  "costs-table-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KİŞİ"])},
  "costs-table-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAALİYET"])},
  "volunteer-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel Bilgiler"])},
  "volunteer-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
  "volunteer-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyisim"])},
  "volunteer-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Posta Adresi"])},
  "volunteer-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "volunteer-gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinsiyet"])},
  "volunteer-birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğum Günü"])},
  "volunteer-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İl"])},
  "volunteer-district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlçe"])},
  "volunteer-education-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğitim"])},
  "volunteer-ed-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğitim Seviyesi"])},
  "volunteer-institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurum"])},
  "volunteer-episode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölüm"])},
  "volunteer-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mezuniyet Durumu"])},
  "volunteer-langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilinen Diller"])},
  "volunteer-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meslek"])},
  "volunteer-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notunuz"])},
  "volunteer-span-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size en kısa sürede dönüş yapacağız."])},
  "volunteer-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönüllü Ol"])},
  "dashboard-frequency-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafta"])},
  "dashboard-frequency-monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay"])},
  "dashboard-frequency-yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıl"])},
  "dashboard-nav-donates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağışlarım"])},
  "dashboard-nav-water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaç Kişiyi Suya Kavuşturdum"])},
  "dashboard-nav-waterweels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim Verdiğim Su Kuyuları"])},
  "dashboard-nav-my-campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalarım"])},
  "dashboard-hello-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merhaba,"])},
  "dashboard-my-panel-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panelim"])},
  "dashboard-total-donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Bağış"])},
  "dashboard-total-waterwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Kuyusu"])},
  "dashboard-total-waterwells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi Suya Kavuştu"])},
  "dashboard-total-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonlandırılan Proje"])},
  "dashboard-total-victim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurban"])},
  "dashboard-my-donates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağışlarım"])},
  "dashboard-donate-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Türü"])},
  "dashboard-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miktar"])},
  "dashboard-donate-type-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Tipi"])},
  "dashboard-campagin-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya Durumu: "])},
  "dashboard-campagin-redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyaya Git"])},
  "dashboard-waterwell-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaç Kişiyi Suya Kavuşturdum"])},
  "dashboard-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim Verdiğim Su Kuyuları"])},
  "dashboard-table-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
  "dashboard-table-project-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Adı"])},
  "dashboard-table-donate-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Miktarı"])},
  "dashboard-table-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi Sayısı"])},
  "dashboard-my-campagins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalarım"])},
  "dashboard-start-spring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya Başlat"])},
  "dashboard-the-spring-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyalar"])},
  "dashboard-campagin-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya üyeleri her ay 45.197 kişiye temiz su getiriyor."])},
  "dashboard-campagin-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "dashboard-kvkk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metnini onaylıyorum."])},
  "dashboard-spring-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya Başlatma Koşulları"])},
  "dashboard-start-campagin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya Başlat"])},
  "dashboard-personal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel Bilgiler"])},
  "dashboard-bring-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Bilgilerimi Getir"])},
  "dashboard-form-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
  "dashboard-form-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyisim"])},
  "dashboard-campagin-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya Bilgileri"])},
  "dashboard-visible-campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkes Tarafından Görünecek Kampanya Adı"])},
  "dashboard-visible-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkes Tarafından Görünecek Resim"])},
  "dashboard-special-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Gün"])},
  "dashboard-campagin-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya Adı"])},
  "dashboard-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükle"])},
  "dashboard-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seç"])},
  "dashboard-about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkında"])},
  "dashboard-desc-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dilerseniz açıklama alanını düzenleyebilirsiniz."])},
  "dashboard-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
  "dashboard-set-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedefinizi Belirleyin"])},
  "dashboard-set-target-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlatmış olacağınız Kampanya 3 ay süre sınırlıdır. Belirtilen süre sonrasında sistem tarafından sonrlandırılacaktır."])},
  "dashboard-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınırlama Olmayacak"])},
  "dashboard-socail-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosyal Hesaplar"])},
  "dashboard-my-campagin-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut kampanya başlatma isteğiniz inceleme altındadır. Onaylandığında burada listelenecektir."])},
  "dashboard-waitings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onay Bekleyenler / Reddedilenler"])},
  "dashboard-application-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başvuru Tarihi"])},
  "dashboard-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
  "dashboard-tab-pers-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel Bilgiler"])},
  "dashboard-tab-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
  "dashboard-tab-donates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağışlar"])},
  "dashboard-tab-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenekler"])},
  "dashboard-cancel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])},
  "dashboard-update-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme Talebinde Bulun"])},
  "dashboard-close-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
  "dashboard-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya silme talebi gönder"])},
  "dashboard-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem yönetici tarafında onaylandığında geri alınamayacaktır."])},
  "dashboard-modal-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır, Devam Edeceğim"])},
  "dashboard-modal-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampana Silme Talebinde Bulun"])},
  "dashboard-update-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut günelleme talebinizin değerlendirme aşamasındadır, talep sonuçlanana kadar herhangi bir değişiklik gerçekleştiremezsin."])},
  "dashboard-camp-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Durum"])},
  "dashboard-camp-status-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut kampanyayı sonlandır"])},
  "dashboard-end-campagin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanyayı Sonlandır"])},
  "dashboard-end-camp-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut kampanya sonlandırılsın mı?"])},
  "dashboard-end-camp-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanması durumunda bu kampanya dahilinde herhangi bir işlem yapılamayacaktır."])},
  "dashboard-modal-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, Sonlandır"])},
  "dashboard-remove-camp-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlatmış olduğum kampanyanın silinmesini istiyorum."])},
  "dashboard-remove-camp-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya Silme Talebinde Bulun"])},
  "dashboard-up-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
  "dashboard-account-remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap silme talebi gönder"])},
  "dashboard-account-remove-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem yönetici tarafında onaylandığında geri alınamayacaktır."])},
  "dashboard-account-remove-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Silme Talebinde Bulun"])},
  "verify-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrula"])},
  "dashboard-total-dont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Bağışlarım"])},
  "dashboard-regular-dont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenli Bağışlarım"])},
  "dashboard-verf-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresini doğrula"])},
  "dashboard-verf-desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("mail")), " e-posta adresine gönderilen kodu girin. Doğrulama e-postasını görüntüleyemiyorsan lütfen spam kutunu kontol et."])},
  "dashboard-send-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden Kod İste"])},
  "dashboard-verf-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-posta adresine gönderilen kodu girin."])},
  "dashboard-verf-success-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama için teşekkürler."])},
  "dashboard-verf-success-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimlere izin vermen durumunda e-posta üzerinden duyurlardan ve gelişmelerden haberder olabilieceksin."])},
  "dashboard-verf-success-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam Et"])},
  "dashboard-notification-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Tercihleri"])},
  "dashboard-accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin Ver"])},
  "dashboard-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
  "dashboard-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Posta"])},
  "dashboard-remove-account-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kişisel bilgilerimin ve hesabının silinmesini istiyorum."])},
  "dashboard-remove-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Silme Talebinde Bulun"])},
  "dashboard-my-cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı Kartlarım"])},
  "dashboard-card-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
  "dashboard-card-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Yöntemi Ekle"])},
  "dashboard-card-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
  "dashboard-card-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
  "dashboard-card-set-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan Yap"])},
  "dashboard-card-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
  "dashboard-card-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı kartın silinsin mi?"])},
  "dashboard-card-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem  geri alınamayacaktır."])},
  "dashboard-card-modal-accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, Kayıtlı Kartımı Sil"])},
  "dashboard-card-default-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay aksi :("])},
  "dashboard-card-default-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmeye çalıştığın kartın varsayılan olarak ayarlanmış. Silmeden önce varsayılan kartını değiştirmelisin."])},
  "dashboard-card-default-modal-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
  "dashboard-set-default-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan kartım yap"])},
  "dashboard-add-card-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Ekle"])},
  "all-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm projelerimiz"])},
  "validation-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatalı Veya Eksik Veri"])},
  "add-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim ekle (zorunlu değil)"])},
  "add-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon ekle (zorunlu değil)"])},
  "donate-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate the name of the person you want to donate."])},
  "dashboard-profile": {
    "update-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilin Başarıyla Güncellendi"])}
  },
  "general": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı"])},
    "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emin Misin?"])}
  },
  "cart": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepetiniz Boş"])},
    "my_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi Kartım"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Numarası"])},
    "card_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart Üzerindeki İsim"])},
    "try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen Tekrar Deneyin"])},
    "failed_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme Alınamadı"])}
  },
  "waterwell": {
    "for_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi Adıma"])},
    "for_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başkası Adına"])},
    "make_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Yap"])},
    "person_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi Sayısı"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "fast_donate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsmin binlerece insana umut olsun."])},
    "fast_donate_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim ekle kısmına bağışı kimin adına yapmak istiyorsanız onu ekleyin."])},
    "get_profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Bilgilerimi Getir"])},
    "donater_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış yapmak istediğiniz kişinin ismini belirtiniz."])},
    "add_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim Ekle"])},
    "add_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon Ekle"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi"])}
  },
  "donate": {
    "standart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standart"])},
    "personality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişi Bazında"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "add_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon Ekle"])},
    "add_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim Ekle"])}
  },
  "dashboard": {
    
  }
}