<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class=" campaign-detail"
    @primaryClick="startSpring"
    modal-size="lg"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/trash.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center header">
        {{ $t("dashboard-account-remove-title") }}
      </h4>
      <p class="gray mb-0">
        {{ $t("dashboard-account-remove-desc") }}
      </p>
      <action-buttons
        @open="handleDelete"
        @close="cancel"
        :close-bt-label="$t('dashboard-modal-continue')"
        open-bt-class="bt-bg-danger"
        open-bt-icon="trash"
        :open-bt-label="$t('dashboard-remove-account-button')"
      />
    </template>
  </el-modal>
</template>

<script>
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox";
import ActionButtons from "@/components/UI/ActionButtons";
import { useRouter } from "vue-router";
import { modalStore } from "@/store/modules/modals";
import ApiService from "@/services/ApiService";
import { Endpoint } from "@/core/network/Endpoint";
import { Endpoints } from "@/core/network/Endpoints";

export default {
  components: {
    ElModal,
    Checkbox,
    ActionButtons,
  },
  setup() {
    const store = modalStore;
    const router = useRouter();
    const handleDelete = async () => {
      const res = await ApiService.U2MClient.put(
        Endpoint.prepare(Endpoints.USER_PROFILE_DELETE_REQUEST)
      );
      if (res.status === 200) {
        cancel()
      }
    };
    const cancel = () => {
      store.commit("closeModal");
    };
    return { handleDelete, cancel };
  },
};
</script>

<style lang="scss" scoped></style>
