<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class="spring-account-modal"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="popupData.img || require('@/assets/images/smile.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="second-font text-center header">
        {{
          popupData.title ||
          "İsteğin bizi çok mutlu etti ama önce hesabına giriş yapman gerekiyor :)"
        }}
      </h4>
      <p class="gray mb-0 text-center">
        {{ popupData.description || " Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor." }}
      </p>
      <button-el
        btn-class="bt-primary"
        :label="$t('login-button')"
        icon="user"
        @click="routeLogin"
      />
    </template>
  </el-modal>
</template>

<script lang="ts">
import { ref } from "vue";
import ElModal from "@/components/UI/Modal.vue";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import { useRouter } from "vue-router";
import { modalStore } from "@/store/modules/modals";
export default {
  components: {
    ElModal,
    ButtonEl,
  },
  setup() {
    const store = modalStore;
    const router = useRouter();
    const popupData = ref(store.getters.getActiveModalData);
    const routeLogin = () => {
      store.commit("closeModal");
      router.push(popupData?.value.button_url);
    };
    return { routeLogin, popupData };
  },
};
</script>

<style lang="scss" scoped></style>
