<template>
  <div class="header-nav-menu outer-nav-menu">
    <img
      :src="require('@/assets/images/hayravesile-logo-dark.svg')"
      alt=""
      class="sidenav-logo"
    />
    <nav class="collapse">
      <NavPills :nav-items="navPills" />
    </nav>
    <button @click="$router.push({ name: 'login' })" class="bt bt-primary">
      <inline-svg :src="require('@/assets/images/icons/circle-user.svg')" />
      <span>
        {{
          userStore.getters.getUser !== null
            ? getTranslation("base_header", "my_panel")
            : getTranslation("base", "login")
        }}
      </span>
    </button>
  </div>
  <div class="header-wrapper" :class="$route.meta.headerWrapper">
    <div
      v-if="$route.meta.headerWrapper === 'subPage'"
      class="header-placeholder"
      :class="{ plus30: $store.getters.yellowHeaderVisible }"
    ></div>
    <header id="header" :class="[$route.meta.header, $route.meta.page]">
      <yellow-header />
      <div class="header-body">
        <div class="container header-container">
          <div class="header-row">
            <header-logo route="home" :navPills="navPills" />
            <div class="header-column">
              <div class="header-row">
                <header-nav class="right-nav" :navPills="navPills" />
                <header-nav-features />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import HeaderLogo from "@/components/Bases/Header/HeaderLogo";
import HeaderNav from "@/components/Bases/Header/HeaderNav";
import HeaderNavFeatures from "@/components/Bases/Header/HeaderNavFeatures";
import YellowHeader from "@/components/Bases/Header/YellowHeader";
import NavPills from "@/components/Bases/Header/NavPills";
import { getTranslation } from "../../../helpers/utilities";
import { headerStore } from "@/store/modules/header";
import { userStore } from "../../../store/modules/UserStore";
import ApiService from "@/services/ApiService";
import { Endpoint } from "@/core/network/Endpoint";
import { Endpoints } from "@/core/network/Endpoints";

export default {
  name: "Header",
  computed: {
    userStore() {
      return userStore;
    },
    headerStore() {
      return headerStore;
    },
  },
  components: {
    YellowHeader,
    HeaderNavFeatures,
    HeaderNav,
    HeaderLogo,
    NavPills,
  },
  data() {
    return {
      basketItems: [],
      isSticky: false,
      prices: [50, 100, 150, 200],
      selectedPrice: 0,
      donateType: "for-once",
      navPills: [],
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      scrollY > 1
        ? document.documentElement.classList.add("oly-sticky-active")
        : document.documentElement.classList.remove("oly-sticky-active");
    });
    // window.addEventListener('resize', () => {
    // if (window.innerWidth > 992) {
    //   // this.$store.commit('closeSideMenu')
    // }
    // })
    if (window.innerWidth < 992) {
      [...document.querySelectorAll(".dropdown-toggle")].forEach((item) => {
        item.addEventListener("click", (e) => {
          e.currentTarget.closest("li").classList.toggle("open");
        });
      });
    }
    this.getNavLinks();
  },
  methods: {
    getTranslation,
    changeSelected(val) {
      this.selectedPrice = +val;
    },
    async getNavLinks() {
      const res = await ApiService.M2MClient.get(
        Endpoint.prepare(Endpoints.MENU)
      );
      this.navPills = res.data.data.map((item) => {
        const pill = {
          nav: item.label,
          routerLink: `/${item.slug}`,
        };
        if (item.subMenuItems && item.subMenuItems.length > 0) {
          pill.subNav = item.subMenuItems.map((subItem) => ({
            nav: subItem.label,
            routerLink: `/${subItem.slug}`,
            icon: "angles-right",
          }));
        }

        return pill;
      });
    },
  },
};
</script>

<style lang="scss">
html {
  .outer-nav-menu {
    @media screen and (min-width: 993px) {
      display: none;
    }
    height: 100vh;
    top: 0;
    left: 0;
    transform: translate3d(-240px, 0, 0);
    backface-visibility: hidden;
    background: #fff;
    font-family: Proxima Nova, sans-serif;
    font-size: 19px;
    z-index: 0;
    width: 240px;
    position: fixed;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    border-left: 1px solid #ebebeb;
    transition: 0.4s ease all;
    padding: 30px;
    display: flex;
    gap: 61px;
    flex-direction: column;

    img {
      width: 131px;
    }

    nav {
      // maybe fix delay
      display: block !important;
      // this is for the bottom one
      @include dashed-border(-30px);

      // this is for the top one
      &::after {
        content: "";
        position: absolute;
        background-position: bottom;
        width: 100%;
        height: 1px;
        background-repeat: repeat-x;
        top: -30px;
        background-image: linear-gradient(
          to right,
          rgba($gray, 0.2) 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-size: 8px 1px;
      }

      #mainNav {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media screen and (max-width: 992px) {
          gap: 24px;
        }

        .dropdown {
          transition: 0.3s ease all;

          &.open {
            .dropdown-menu {
              display: flex;
              border: 0;
              box-shadow: none;
              position: unset;
            }
          }
        }
      }
    }

    > .bt {
      margin: auto auto 0;
      width: 100%;
    }
  }

  .header-wrapper.subPage {
    .header-placeholder {
      height: 80px;
    }

    #header .header-body {
      height: 80px;

      .header-nav {
        min-height: unset;
      }

      .header-nav-features .header-nav-feature {
        &:first-child {
          border-right-color: $light;
        }

        > a {
          color: $dark;

          &:hover {
            color: $primary;
          }
        }

        button svg {
          fill: $secondary;
        }
      }
    }
  }

  #header {
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);

    &.spring .header-nav .header-nav-menu nav > ul > li > a {
      &:hover,
      &.router-link-active {
        color: $warning;

        &::before {
          background-color: $warning;
        }
      }
    }

    .header-body {
      height: 100px;
      border-bottom: 0;
    }

    .header-container {
      height: 100%;
      @media screen and (max-width: 992px) {
        padding: 0;
        margin: 0;
        max-width: unset;
      }
    }
  }

  &.oly-sticky-active {
    .header-wrapper.subPage {
      .header-placeholder {
        height: 50px;
      }

      #header {
        .header-body {
          height: 50px;
        }
      }
    }

    #header {
      &.spring .header-nav .header-nav-menu nav > ul > li > a {
        &:hover,
        &.router-link-active {
          color: $warning !important;

          &::before {
            background-color: $warning;
          }
        }
      }

      .header-nav .header-nav-menu nav > ul > li > a {
        &:hover,
        &.router-link-active {
          color: $primary !important;

          &::before {
            background-color: $primary;
          }
        }
      }

      .header-body {
        height: 50px;
      }
    }
  }
}
</style>
