import { translationStore } from "@/store/modules/translationStore";

export default class TranslationMiddleware {

  public async protect() {
    const store = translationStore;
    if (store.getters.translations !== null) {
      return true;
    }
    await store.dispatch('getTranslations');
    return store.getters.translations !== null;
  }


}