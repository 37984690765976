<template>
  <div class="header-placeholder"></div>
  <header id="header" class="white" :class="$route.meta.header">
    <div class="header-body">
      <div class="container header-container">
        <div class="header-row">
          <button-el @click="emitBackClick" btn-class="bt-transparent back-arr" icon="arrow-left" />
            <header-logo  route="home"/>
          <div class="header-column">
            <div class="header-row justify-content-between custom-nav">
              <p class="gray mb-0">Bağış</p>
              <button-el
                v-if="userStore.getters.getUser === null"
                @click="$router.push('/auth/login')"
                btn-class="bt-transparent-danger"
                :label="$t('nav-login')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderLogo from "@/components/Bases/Header/HeaderLogo";
import ButtonEl from "@/components/UI/ButtonEl";
import { userStore } from "@/store/modules/UserStore";
import { ref } from "vue";
import router from "@/router";

export default {
  name: "SubPageHeader",
  computed: {
    userStore() {
      return userStore
    }

  },
  setup(){
    const store = userStore
    const isLoggedIn = ref(store.getters.getUser !== null)
    const emitBackClick= () => {
      router.go(-1)
    }
    return {
      isLoggedIn,
      emitBackClick
    }

  },
  components: { ButtonEl, HeaderLogo },
};
</script>

<style lang="scss">
.header-placeholder {
  height: 100px;
}
#header.payment {
  .custom-nav {
    margin: 0 24px;
    .bt {
      background: none;
      border: none;
      height: 100%;
    }
  }
  @media screen and (max-width: 992px) {
    .outer-wrapper .left-nav {
      display: none !important;
    }
  }
  @media screen and (max-width: 500px) {
    .outer-wrapper {
      padding: 28px 12px;
    }
    .custom-nav {
      margin: 0 12px;
    }
    .bt.back-arr {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
</style>
