import ApiService from "@/services/ApiService";
import {Endpoint} from "@/core/network/Endpoint";
import {Endpoints} from "@/core/network/Endpoints";

import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

export interface State {
    user?: any
}

export const key: InjectionKey<Store<State>> = Symbol()

export const userStore = createStore<State>({
    state: {
        user: null
    },
    getters: {
        getUser(state:any) {
            return state.user;
        }
    },
    mutations: {
        setUser(state:any, payload:any) {
            state.user = payload
        },

    },
    actions: {
        async showUser(state:any) {
            try{
                const res = await ApiService.U2MClient.get(Endpoint.prepare(Endpoints.USER_PROFILE))
                userStore.commit('setUser', res.data.data);
            }catch (e){

            }
        },
        clearUser(){
            userStore.commit('setUser',null)
        }
    }
})

// define your own `useStore` composition function
export function useStore () {
    return baseUseStore(key)
}