import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multi_select2 = _resolveComponent("multi-select2")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["drop-wrapper", _ctx.isFluid === 1 ? 'fluid' : '']),
    style: _normalizeStyle({ width: `${_ctx.width}px`, height: `${_ctx.height}px` })
  }, [
    _createVNode(_component_multi_select2, {
      modelValue: _ctx.dropValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dropValue) = $event)),
      "can-deselect": false,
      options: _ctx.options,
      onSelect: _ctx.listenSelect
    }, null, 8, ["modelValue", "options", "onSelect"]),
    _createVNode(_component_inline_svg, {
      fill: "#F36E2A",
      src: require(`@/assets/images/icons/down.svg`),
      width: "10",
      height: "12"
    }, null, 8, ["src"])
  ], 6))
}