<template>
  <ul class="dropdown-list">
    <li class="drop-item" v-for="item in items">
      <router-link :to="item.routerLink">
        {{ item.nav }}
        <inline-svg :src="require('@/assets/images/icons/angles-right.svg')" />
      </router-link>
    </li>
    <li class="drop-item" v-if="logoutItem">
      <a  @click="handleLogout">
        {{$t("global.navs.logout")}}
        <inline-svg
          :src="require('@/assets/images/icons/arrow-right-to-bracket.svg')"
        />
      </a>
    </li>
  </ul>
</template>

<script>
import { useRouter } from "vue-router";
import ApiService from "@/services/ApiService";
import { Endpoint } from "@/core/network/Endpoint";
import { Endpoints } from "@/core/network/Endpoints";
import { destroyToken } from "@/services/JwtService";
import { userStore } from "@/store/modules/UserStore";

export default {
  name: "Dropdown",
  components: {},
  setup(){
    const router = useRouter()
    const handleLogout = async ()=> {
      try{
        const res = await ApiService.U2MClient.delete(Endpoint.prepare(Endpoints.USER_AUTH_LOGOUT))
        if(res.status == 200 || res.status == 204){
          destroyToken()
          userStore.dispatch("clearUser").then(() => router.push('/'))

        }
      }catch (e){
        alert("logout fail")
      }
    };
    return {
      handleLogout
    };
  },
  props: {
    items: Array,
    logoutItem: Boolean,
  },
};
</script>

<style lang="scss">
.dropdown-handler {
  position: relative;
  .dropdown-list {
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
    top: 100%;
    min-width: 200px;
    right: 0;
    border: 1px solid $light;
    background: $lighter;
    box-shadow: $main-card-shadow;
    .drop-item {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover,
        &.router-link-active {
          color: $primary;
          svg {
            fill: $primary;
          }
        }
        &,
        svg {
          color: $gray;
          transition: $trns;
        }
      }
      &:not(:last-child) {
        padding-bottom: 15px;
        border-bottom: 1px solid $lighter;
      }
    }
  }
}
</style>
