<template>
  <Modal
    v-if="LOGIN_MODAL"
    @close="$store.commit('closeModal', 'loginModal')"
    modal-wrapper-class=" login-modal"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/smile.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="second-font text-center header">
        {{ $t("mdl-title") }}
      </h4>
      <p class="gray mb-0 text-center">
        {{ $t("mdl-desc") }}
      </p>
      <button-el
        btn-class="bt-primary"
        :label="$t('login-button')"
        icon="user"
      />
    </template>
  </Modal>
  <Modal
    v-if="DONATE_MODAL"
    @close="$store.commit('closeModal', 'donateModal')"
    modal-wrapper-class="donate-modal "
  >
    <template v-slot:body>
      <type-buttons />
      <price-inputs />
      <p class="mb-0 gray bold span-text">
        {{ $t("slider-card-description") }}
      </p>
    </template>
  </Modal>
  <Modal
    v-if="NAMED_WATER_DONATE_MODAL"
    @close="$store.commit('closeModal', 'namedWaterDonate')"
    modal-wrapper-class=" named-water-donate-modal"
    modal-size="sm"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/smile.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <div class="header-wrapper">
        <h4 class="text-center header">İsmin binlerece insana umut olsun.</h4>
        <p class="gray mb-0 text-center">
          İsim ekle kısmına bağışı kimin adına yapmak istiyorsanız onu ekleyin.
        </p>
        <ul class="nav nav-pills radio-buttons" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active custom-bt"
              id="button-1"
              data-bs-toggle="pill"
              data-bs-target="#tab-1"
              role="tab"
              type="button"
              aria-controls="standard-tab"
              aria-selected="true"
            >
              {{ $t("organised") }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link custom-bt"
              id="button-2"
              data-bs-toggle="pill"
              data-bs-target="#tab-2"
              role="tab"
              type="button"
              aria-controls="single-tab"
              aria-selected="true"
            >
              {{ $t("per-person") }}
            </button>
          </li>
        </ul>
      </div>
      <div class="footer">
        <div class="content-wrapper">
          <div class="tab-content" id="content">
            <div
              class="tab-pane fade active show"
              id="tab-1"
              role="tabpanel"
              aria-labelledby="button-standard-tab"
            >
              <div class="inputs-line">
                <multi-select2
                  placeholder="Select"
                  :options="['option1', 'option2']"
                />
                <div class="total-wrapper">
                  <p class="btn-text gray">Tutar</p>
                  <p class="btn-text dark">₺80,00</p>
                </div>
              </div>
              <p class="gray span-text">
                Bağış yapmak istediğiniz kişinin ismini belirtiniz.
              </p>
              <div class="inputs-line">
                <input-wrapper type="text" placeholder="Isim ekle" />
                <input-wrapper type="numeric" placeholder="Telefon Ekle" />
              </div>
            </div>
          </div>
        </div>
        <div class="bt bt-bg-primary">Su kuyusuna isim ver</div>
      </div>
    </template>
  </Modal>
  <Modal
    v-if="CODE_INPUTS_MODAL"
    @close="closeCodeInputsModal"
    modal-wrapper-class="code-inputs"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/Verify_Email.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center header">{{ $t("dashboard-verf-title") }}</h4>
      <p class="gray mb-0 text-center">
        {{ $t("dashboard-verf-desc") }}
      </p>
      <div class="code-inputs-wrapper">
        <code-inputs />
        <button-el
          @click="nextStep"
          :isDisabled="!check"
          type="button"
          :label="$t('verify-button')"
          icon="shield"
          btn-class="bt-primary"
          style="width: 100%"
        />
        <!--Needed to provide width here cause it's not working in this file's styles, even if it's not scoped-->
      </div>
      <div class="new-code">
        {{ codeInputsModal }}
        <span class="btn-text primary medium">{{
          $t("dashboard-send-code")
        }}</span>
      </div>
    </template>
  </Modal>
  <Modal
    v-if="VALIDATION_DONE_MODAL"
    @close="$store.commit('closeModal', 'validationDoneModal')"
    modal-wrapper-class=" validationDone"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/check.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center header">
        {{ $t("dashboard-verf-success-title") }}
      </h4>
      <p class="gray mb-0 text-center">
        {{ $t("dashboard-verf-success-desc") }}
      </p>
      <button-el
        type="button"
        :label="$t('continue-button')"
        icon="angles-right"
        btn-class="bt-danger"
        @click="nextPage"
      />
    </template>
  </Modal>
  <Modal
    v-if="WATER_WELL_QUICK_DONATE"
    @close="$store.commit('closeModal', 'waterWellQuickDonate')"
    modal-wrapper-class="water-donate-modal"
    modal-size="sm"
  >
    <template v-slot:body>
      <div class="block-header">
        <div>
          <p class="title-badge span-text" style="margin-bottom: 15px">
            <inline-svg
              fill="#f36e2a"
              :src="require('@/assets/images/icons/street-view.svg')"
            />
            Çad
          </p>
          <h4 class="" style="margin-bottom: 5px">₺2,024</h4>
          <p class="gray mb-0 span-text bold">hedeflenen ₺200,000</p>
        </div>
        <div class="chart-wrapper">
          <chart type="orange-bar" bars="single" :percent="72" />
        </div>
      </div>
      <ul class="nav nav-pills radio-buttons w-100" role="tablist">
        <li class="nav-item flex-grow-1" role="presentation">
          <button
            class="nav-link active custom-bt"
            id="button-5"
            data-bs-toggle="pill"
            data-bs-target="#tab-5"
            role="tab"
            type="button"
            aria-controls="tab-5"
            aria-selected="true"
          >
            Standart
          </button>
        </li>
        <li class="nav-item flex-grow-1" role="presentation">
          <button
            class="nav-link custom-bt"
            id="button-6"
            data-bs-toggle="pill"
            data-bs-target="#tab-6"
            role="tab"
            type="button"
            aria-controls="tab-5"
            aria-selected="true"
          >
            Kişi Bazında
          </button>
        </li>
      </ul>
      <div class="content-wrapper">
        <div class="tab-content" id="content">
          <div
            class="tab-pane fade active show"
            id="tab-5"
            role="tabpanel"
            aria-labelledby="button-standard-tab"
          >
            <div class="inputs-wrapper">
              <price-inputs :terminate="true" />
              <a href="" class="primary btn-text text-end"
                >Profil Bilgilerimi Getir</a
              >
              <input-wrapper type="Text" placeholder="Isim Ekle" />
              <input-wrapper type="numeric" placeholder="Telefon Ekle" />
              <button-el btn-class="bt-bg-primary w-100" label="Destek Ol" />
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="tab-6"
            role="tabpanel"
            aria-labelledby="button-standard-tab"
          >
            <div class="inputs-wrapper">
              <multi-select2
                placeholder="select"
                :options="['2 kişi', '4 kişi']"
              />
              <div class="terminate-project w-100">
                <p class="btn-text gray mb-0 medium">{{ $t("end-project") }}</p>
                <p class="btn-text dark mb-0 text-left bold">₺122,024</p>
              </div>
              <div class="input-wrapper">
                <input type="text" placeholder="Isim Ekle" />
                <a href="" class="primary btn-text">Profil bilgilerimi getir</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal
    v-if="SACRIFICE_MODAL"
    @close="$store.commit('closeModal', 'sacrificeModal')"
    modal-wrapper-class="sacrifice-modal"
    modal-size="sm"
  >
    <template v-slot:body>
      <ul class="nav nav-pills radio-buttons w-100" role="tablist">
        <li class="nav-item flex-grow-1" role="presentation">
          <button
            class="nav-link custom-bt"
            id="button-8"
            data-bs-toggle="pill"
            data-bs-target="#tab-8"
            role="tab"
            type="button"
            aria-controls="tab-5"
            aria-selected="true"
          >
            Standart
          </button>
        </li>
        <li class="nav-item flex-grow-1" role="presentation">
          <button
            class="nav-link active custom-bt"
            id="button-9"
            data-bs-toggle="pill"
            data-bs-target="#tab-9"
            role="tab"
            type="button"
            aria-controls="tab-5"
            aria-selected="true"
          >
            Kişi Bazında
          </button>
        </li>
      </ul>
      <div class="content-wrapper">
        <div class="tab-content" id="content">
          <div
            class="tab-pane fade active show"
            id="tab-9"
            role="tabpanel"
            aria-labelledby="button-standard-tab"
          >
            <div class="inputs-wrapper">
              <multi-select2
                placeholder="select"
                :options="['2 kişi', '4 kişi']"
              />
              <div class="terminate-project w-100">
                <p class="btn-text gray mb-0 medium">{{ $t("end-project") }}</p>
                <p class="btn-text dark mb-0 text-left bold">₺122,024</p>
              </div>
              <div class="inputs">
                <input-wrapper type="text" placeholder="Isim Ekle" />
                <input-wrapper type="numeric" placeholder="Telefon Ekle" />
              </div>
              <checkbox id="013">
                <temlplate> Veklatimi verdim.</temlplate>
              </checkbox>
              <button-el btn-class="bt-bg-primary" label="Destek Ol" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal
    v-if="SPRING_DONATE_MODAL"
    @close="$store.commit('closeModal', 'springDonateModal')"
    modal-wrapper-class="spring-modal"
    modal-size="sm"
  >
    <template v-slot:body>
      <div class="header">
        <h5 class="medium">Su krizini çözmede</h5>
        <p class="bold mb-0 gray span-text">Ayda ₺5irsiniz.</p>
      </div>
      <div class="prices-wrapper">
        <price-inputs :includeBtn="false" @selectedVal="changeSelected" />
      </div>
      <div class="inputs-wrapper">
        <labeled-input-wrapper
          type="text"
          :placeholder="$t('form-name')"
          :label="$t('form-name')"
          icon="user"
        />
        <labeled-input-wrapper
          type="textarea"
          :placeholder="$t('form-message')"
          :label="$t('form-message')"
        />
        <button-el
          btn-class="bt-bg-primary w-100"
          :label="$t('support-text')"
        />
      </div>
    </template>
  </Modal>
  <Modal
    v-if="CAMPAIGN_DETAILS_MODAL"
    @close="$store.commit('closeModal', 'campaignDetailsModal')"
    modal-wrapper-class=" campaign-detail"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/trash.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center second-font header">
        {{ $t("dashboard-modal-title") }}
      </h4>
      <p class="gray mb-0 text-center">
        {{ $t("dashboard-modal-desc") }}
      </p>
      <action-buttons
        @open=""
        @close=""
        :close-bt-label="$t('dashboard-modal-continue')"
        open-bt-class="bt-bg-danger"
        open-bt-icon="trash"
        :open-bt-label="$t('dashboard-modal-remove')"
      />
    </template>
  </Modal>
  <Modal
    v-if="CAMPAIGN_TARGET_MODAL"
    @close="$store.commit('closeModal', 'campaignTargetModal')"
    modal-wrapper-class="campaign-target-modal"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/check.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center second-font header">
        {{ $t("dashboard-end-camp-modal-title") }}
      </h4>
      <p class="gray mb-0 text-center">
        {{ $t("dashboard-end-camp-modal-desc") }}
      </p>
      <action-buttons
        @open=""
        @close=""
        :close-bt-label="$t('dashboard-modal-continue')"
        :open-bt-label="$t('dashboard-modal-end')"
        open-bt-class="bt-success"
        open-bt-icon="badge-check"
      />
    </template>
  </Modal>
  <Modal
    v-if="MY_CAMPAIGN_MODAL"
    @close="$store.commit('closeModal', 'myCampaignModal')"
    @primary-click="
      () => {
        $router.push({ name: 'new-campaigns' });
        $store.commit('closeModal', 'myCampaignModal');
      }
    "
    btnClass="bt-golden"
    btnIcon="face-smile"
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class="my-campaign-modal "
  >
    <template v-slot:header>
      <img class="w-100" :src="require('@/assets/images/modal.png')" alt="" />
    </template>
    <template v-slot:body>
      <div class="header">
        <span class="nav-text warning bold">TheSpring</span>
        <h4 class="text-center second-font">
          {{ $t("the-spring-title") }}
        </h4>
      </div>
      <p class="gray text-center mb-0">
        Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla
        in. Quam mauris in tellus viverra hac diam vel, tellus tortor.
      </p>
      <checkbox v-model="kvkkCheckbox" id="kvkk">
        <template v-slot>
          <a href="#" class="primary" @click="goDetailPage">KVKK</a>
          {{ $t("dashboard-kvkk") }}
        </template>
      </checkbox>
      <!--      {{ this.v$.kvkkCheckbox }}-->
    </template>
  </Modal>
  <Modal
    v-if="PROFILE_OPTIONS_MODAL"
    @close="$store.commit('closeModal', 'profileOptionsModal')"
    modal-wrapper-class=""
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/trash.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center header">
        {{ $t("dashboard-account-remove-title") }}
      </h4>
      <p class="gray mb-0">
        {{ $t("dashboard-account-remove-desc") }}
      </p>
      <action-buttons
        @open=""
        @close=""
        :close-bt-label="$t('dashboard-modal-continue')"
        open-bt-class="bt-bg-danger"
        open-bt-icon="trash"
        :open-bt-label="$t('dashboard-remove-account-button')"
      />
    </template>
  </Modal>
  <Modal
    v-if="DONATE_TABLE_ITEM_DETAIL"
    @close="$store.commit('closeModal', 'donateTableItemDetail')"
    modal-wrapper-class="donate-table-item-detail"
  >
    <template v-slot:body>
      <p class="gray text-center mb-0">
        Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla
        in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis
        massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in.
        Quam mauris in tellus viverra hac diam vel, tellus tortor.
      </p>
    </template>
  </Modal>
  <Modal
    v-if="START_SPRING_ACCOUNT_MODAL"
    @close="$store.commit('closeModal', 'startSpringAccountModal')"
    modal-wrapper-class="spring-account-modal"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/smile.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="second-font text-center header">
        İsteğin bizi çok mutlu etti ama önce hesabına giriş yapman gerekiyor :)
      </h4>
      <p class="gray mb-0 text-center">
        Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla
        in. Quam mauris in tellus viverra hac diam vel, tellus tortor.
      </p>
      <button-el
        btn-class="bt-primary"
        :label="$t('login-button')"
        icon="user"
      />
    </template>
  </Modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { mapGetters } from "vuex";
import Modal from "@/components/UI/Modal";
import ButtonEl from "@/components/UI/ButtonEl";
import TypeButtons from "@/components/Pages/Base/components/TypeButtons";
import PriceInputs from "@/components/Pages/Base/components/PriceInputs";
import InputWrapper from "@/components/UI/InputWrapper";
import CodeInputs from "@/components/Auth/CodeInputs";

import {
  VALID_EMAIL,
  LOGIN_MODAL,
  DONATE_MODAL,
  SACRIFICE_MODAL,
  CODE_INPUTS_MODAL,
  MY_CAMPAIGN_MODAL,
  NAMED_WATER_DONATE_MODAL,
  SPRING_DONATE_MODAL,
  PROFILE_OPTIONS_MODAL,
  CAMPAIGN_TARGET_MODAL,
  VALIDATION_DONE_MODAL,
  CAMPAIGN_DETAILS_MODAL,
  WATER_WELL_QUICK_DONATE,
  DONATE_TABLE_ITEM_DETAIL,
  START_SPRING_ACCOUNT_MODAL,
} from "@/store/constants";
import Chart from "@/components/UI/Chart";
import LabeledInputWrapper from "@/components/UI/LabeledInputWrapper";
import ActionButtons from "@/components/UI/ActionButtons";
import Checkbox from "@/components/UI/Checkbox";

export default {
  name: "Modals",
  components: {
    Checkbox,
    ActionButtons,
    LabeledInputWrapper,
    Chart,
    CodeInputs,
    InputWrapper,
    PriceInputs,
    TypeButtons,
    ButtonEl,
    Modal,
  },
  data: () => ({
    v$: useVuelidate(),
    codeInputsModalData: {
      finalCode: "",
    },
    sacrificeModalData: {
      selectedValue: "",
    },
    kvkkCheckbox: false,
  }),
  validations() {
    return {
      kvkkCheckbox: { required },
    };
  },
  methods: {
    getFinalCode(e) {
      this.codeInputsModalData.finalCode = e;
    },
    nextStep() {
      if (this.$store.getters.userCode === this.$store.getters.passCode) {
        this.$store.commit("closeModal", "codeInputsModal");
        this.$store.commit("showModal", "validationDoneModal");
      }
    },
    nextPage() {
      this.$router.push({ name: "profile-options" });
      this.$store.commit("closeModal", "validationDoneModal");
      this.$store.commit(VALID_EMAIL);
    },
    changeSelected(val) {
      this.selectedPrice = +val;
    },
    closeCodeInputsModal() {
      this.$store.commit("closeModal", "codeInputsModal");
      document.querySelectorAll(".inputs input").forEach((input) => {
        input.value = "";
        this.inputs = [];
        input.classList.remove("valid");
      });
    },
    goDetailPage() {
      //  may put parameters to navigate
      this.$router.push({ name: "terms-of-use" });
      this.$store.commit("closeModal", "myCampaignModal");
    },
  },
  computed: {
    check() {
      return this.$store.getters.userCodeLength === 6;
    },
    MY_CAMPAIGN_MODAL() {
      this.v$.touch();
    },
    ...mapGetters([
      LOGIN_MODAL,
      DONATE_MODAL,
      NAMED_WATER_DONATE_MODAL,
      CODE_INPUTS_MODAL,
      VALIDATION_DONE_MODAL,
      WATER_WELL_QUICK_DONATE,
      SACRIFICE_MODAL,
      SPRING_DONATE_MODAL,
      CAMPAIGN_DETAILS_MODAL,
      CAMPAIGN_TARGET_MODAL,
      MY_CAMPAIGN_MODAL,
      PROFILE_OPTIONS_MODAL,
      DONATE_TABLE_ITEM_DETAIL,
      START_SPRING_ACCOUNT_MODAL,
    ]),
  },
};
</script>

<style lang="scss">
.modal-wrapper {
  &.login-modal {
    .modal {
      min-width: unset;
    }
  }

  &.campaign-detail {
    .modal {
      .modal__header {
        margin-bottom: 30px;
      }

      .action-buttons {
        //flex-direction: column;
      }
    }
  }

  &.donate-modal {
    .modal {
      min-width: unset;
      padding-bottom: 30px;

      .bt-close:hover {
        background-color: $danger;

        span {
          color: $white;
        }

        svg {
          fill: $white;
        }
      }

      .modal-body {
        padding: 0 30px;

        .form-wrapper {
          width: 100%;

          .price-area .item {
            width: calc(50% - 3px);
          }
        }
      }
    }
  }

  &.named-water-donate-modal {
    .modal {
      @media screen and (max-width: 992px) {
        width: 600px;

        .modal__header {
          width: 15%;
          margin-bottom: 15px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .modal-body {
          .header-wrapper {
            gap: 15px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        width: 500px;
      }

      @media screen and (max-width: 576px) {
        width: 350px;

        .modal__header {
          width: 7%;
          margin-bottom: 15px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .modal-body {
          gap: 30px;

          .header-wrapper {
            gap: 5px;

            &::before {
              display: none;
            }

            h4 {
              font-size: 18px !important;
            }

            ul {
              flex-direction: row;
              justify-content: space-between;

              li {
                width: 50%;

                button {
                  width: 100%;
                }
              }
            }
          }

          .footer {
            .content-wrapper {
              .tab-content {
                .tab-pane {
                  .inputs-line {
                    flex-direction: column;

                    .drop-wrapper {
                      .multiselect {
                        height: 100%;
                      }
                    }

                    .input-wrapper {
                      input {
                        height: 48px;
                      }
                    }

                    .drop-wrapper,
                    .total-wrapper,
                    .input-wrapper {
                      width: 100%;
                      height: 40px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal-body {
      gap: 60px;

      .footer {
        .content-wrapper {
          .tab-content {
            .tab-pane {
              .inputs-line {
                .drop-wrapper {
                  height: 56px !important;

                  svg {
                    top: 40%;
                  }

                  .multiselect {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .header-wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include dashed-border(-30px);

        .radio-buttons {
          justify-content: center;

          button {
            width: 150px;
          }
        }
      }

      .footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tab-pane {
          width: 100%;
          display: flex;
          flex-direction: column;

          .inputs-line {
            display: flex;
            gap: 16px;

            > * {
              width: 50%;
            }

            .multiselect .multiselect__tags {
              background: $lighter;
            }

            .total-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 20px 18px;
              background: $light;

              p {
                margin-bottom: 0;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: -0.03em;
              }
            }
          }

          > p {
            margin: 16px 0 6px;
          }
        }

        > .bt {
          width: max-content;
          margin-top: 16px;
        }
      }
    }
  }

  &.my-campaign-modal {
    .modal {
      .header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        span {
          display: block;
          background: rgba($warning, 0.1);
          padding: 8px;
          width: max-content;
        }
      }

      .bt-close {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);

        span {
          color: $white;
        }

        svg {
          fill: rgba($white, 0.4);
        }

        &:hover {
          background: $danger;
        }
      }

      .modal-body {
        margin-bottom: 30px;

        .form-group {
          margin-bottom: 0;
        }
      }

      .check-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;

        a {
          text-decoration: none;
        }
      }
    }
  }

  &.water-donate-modal {
    .modal {
      padding-bottom: 0;

      .modal__header {
        margin-bottom: 0;
      }

      .modal-body {
        padding: 0 30px;
        margin: 60px 0 30px;

        .block-header {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .chart-wrapper {
            width: 90px;

            .chart {
              height: 100%;

              .text {
                font-size: 16px;
                transform: translate(-4px, 11px);
              }
            }
          }
        }

        .inputs-wrapper {
          display: flex;
          flex-direction: column;
          gap: 6px;
          align-items: flex-end;

          .bt {
            margin-top: 10px;
          }

          .multiselect {
            height: 58px;
            margin-bottom: 10px;
          }

          .input-wrapper {
            position: relative;
            transition: $trns;
            margin-top: 10px;

            input {
              padding: 12px 36px 12px 16px;
              width: 100%;
              border: 1px solid $light;
              background: $lighter;
              color: $gray;
              transition: $trns;

              &:focus,
              &.valid {
                outline: none;
                border: 1px solid $primary;
                color: $dark;
                background: white;

                & + svg {
                  fill: $primary;
                }
              }

              &:disabled {
                background: #eff0f2;
                border: 1px solid #eff0f2;
                cursor: not-allowed;
                user-select: none;
              }
            }

            a {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
            }
          }
        }

        .form-wrapper {
          width: 100%;
          padding-bottom: 0;
          margin-bottom: 10px;

          &::before {
            content: unset;
          }
        }
      }
    }
  }

  &.sacrifice-modal {
    .modal {
      padding-bottom: 0;

      .modal-body {
        gap: 16px;
        padding: 0 30px 30px;
        width: 100%;

        .content-wrapper {
          width: 100%;
        }

        #tab-8,
        #tab-9 .inputs-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;

          .multiselect {
            height: 58px;
          }

          .inputs {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .input-wrapper input {
              background: #fbfbfb !important;
              border: 1px solid #eff0f2 !important;
            }
          }

          .custom-checkbox {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.spring-modal {
    .modal {
      min-width: unset;
      padding-bottom: 30px;

      .modal-body {
        .header {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: 100%;
          @include dashed-border(-15px);
        }

        .prices-wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: 100%;

          .form-wrapper {
            gap: 5px;
            padding-bottom: 0;
            @include dashed-border(-15px);

            .price-area {
              .item {
                font-size: 16px;
                line-height: 34px;
              }
            }
          }
        }

        .inputs-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;

          .bt {
            margin-top: -1px;
          }

          .textarea-wrapper {
            textarea {
              height: 120px;
            }
          }
        }
      }
    }
  }

  &.code-inputs {
    .modal {
      .modal__header {
        margin-bottom: 30px;
      }

      .code-inputs {
        .inputs {
          margin-top: 0;
          align-items: stretch;
          width: 100%;

          input {
            width: inherit;
          }
        }
      }
    }
  }

  &.spring-account-modal {
    .modal {
      #modalTitle {
        margin-bottom: 30px;
      }

      p {
      }
    }
  }
}
</style>
