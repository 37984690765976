import TokenModel from "@/store/network/TokenModel";
import {TokenTypeEnum} from "@/store/network/TokenTypeEnum";
export const getToken = (tokenType: TokenTypeEnum): TokenModel => {
    const expireAt = window.localStorage.getItem("expire_at");
    return new TokenModel(
        window.localStorage.getItem(tokenType),
        expireAt !== null ? new Date(expireAt) : null
    );
};


export const saveToken = (
    tokenType: TokenTypeEnum,
    token: TokenModel
): void => {
    if (token.token !== null) window.localStorage.setItem(tokenType, token.token);
    if (token.expireAt !== null)
        window.localStorage.setItem("expire_at", token.expireAt.toString());
};


export const destroyToken = (tokenType: TokenTypeEnum): void => {
    window.localStorage.removeItem(tokenType);
    window.localStorage.removeItem("expire_at");
};

export default { getToken, saveToken, destroyToken };