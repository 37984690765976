<template>
  <div class="type-buttons">
    <button
      v-for="(item, n) in donateTypes"
      class="custom-bt"
      :class="n === 0 ? 'selected' : ''"
      @click="setDonateType($event, item.type)"
    >
      {{ item.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: "TypeButtons",
  data() {
    return {
      donateTypes: [
        {
          type: "for-once",
          label: this.$t("for-once"),
        },
        {
          type: "organised",
          label: this.$t("organised"),
        },
      ],
    };
  },
  methods: {
    setDonateType(e, type) {
      document.querySelector(".bt.selected").classList.remove("selected");
      e.target.classList.add("selected");
      this.$emit("donateType", type);
    },
  },
};
</script>

<style lang="scss">
.type-buttons {
  display: flex;
}
</style>
