<template>
  <div class="custom-checkbox form-group">
    <input
        type="checkbox"
        :id="id"
        v-model="value"
    />
    <label :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
export default {
  name: "Checkbox",
  props: {
    id: String,
    modelValue: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, {emit}) {
    const value = ref(props.modelValue || false);
    onMounted(() => {
      watch(value, (val) => {
        emit('update:modelValue', val)
      });
    });
    return {
      value,
    }
  }
};
</script>

<style lang="scss">
.custom-checkbox {
  display: block;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 700;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked + label::after {
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      left: 9px;
      width: 6px;
      height: 14px;
      border: solid $primary;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  label {
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid $light;
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 12px;
      top: -2px;
    }

    a {
      transition: $trns;

      &:hover {
        color: currentColor;
      }
    }
  }

  &.cb-bg-secondary {
    input {
      &:checked {
        & + label {
          &::after {
            border-color: white;
            top: 1px;
          }

          &::before {
            background-color: $secondary;
          }
        }
      }
    }

    label {
      &::before {
        background: white;
        border: 1px solid $secondary;
      }
    }
  }
}
</style>
