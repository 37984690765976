import {
    NEW_MEMBER,
    RESET,
    SEND_AUTH_CODE,
    UPDATE_PHONE,
    RESET_CODE,
    UPDATE_CODE,
    VALID_EMAIL,
} from "@/store/constants";

// in case you have to store the values
//https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store/

const login = {
    state: (): any => ({
        loginState: "login",
        userPhoneNumber: "",
        passCode: "222222",
        userCode: "",
        validated: false,
        countryCode: "",
    }),
    mutations: {
        [SEND_AUTH_CODE](state: any) {
            state.loginState = "auth-step";
        },
        [NEW_MEMBER](state: any) {
            state.loginState = "sign-up";
        },
        [RESET](state: any) {
            state.loginState = "login";
        },
        [UPDATE_PHONE](state: any, value: any) {
            state.userPhoneNumber = value;
        },
        [RESET_CODE](state: any) {
            state.userCode = "";
        },
        [UPDATE_CODE](state: any, code: any) {
            state.userCode = code;
        },
        [VALID_EMAIL](state: any) {
            state.validated = true;
        },
        updateCountryCode(state: any, code: any) {
            state.countryCode = code;
        },
        SET_LOGGED_IN(state: any, value: any) {
            state.user.loggedIn = value;
        },
        SET_USER(state: any, data: any) {
            state.user.data = data;
        },
    },
    getters: {
        user(state: any) {
            return state.user;
        },
        userCodeLength(state: any, getters: any) {
            return getters.userCode.length;
        },
        userCode(state: any) {
            return state.userCode;
        },
        passCode(state: any) {
            return state.passCode;
        },
        validated(state: any) {
            return state.validated;
        },
        countryCode(state: any) {
            return state.countryCode;
        },
    },
};

export default login;
