<template>
  <div class="breadcrumb">
    <div class="container">
      <ul>
        <li>
          <a href="">
            <inline-svg
              :src="require('@/assets/images/icons/home-heart.svg')"
              fill="#f36e2a"
            />
          </a>
        </li>
        <li>
          <a class="gray" href="">{{ $route.meta.title || "title" }}</a>
        </li>
      </ul>
      <router-link class="home-link" to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            class="colorful-path"
            d="M4.16699 11.7158C4.16699 10.8742 4.16699 10.4542 4.35199 10.095C4.53616 9.73667 4.87866 9.49167 5.56283 9.00334L9.03199 6.525C9.49866 6.19167 9.73366 6.02417 10.0003 6.02417C10.267 6.02417 10.5012 6.19084 10.9687 6.525L14.4378 9.0025C15.122 9.49167 15.4645 9.73584 15.6487 10.095C15.8337 10.4533 15.8337 10.8742 15.8337 11.715V15.8333C15.8337 16.6192 15.8337 17.0117 15.5895 17.2558C15.3453 17.5 14.9528 17.5 14.167 17.5H5.83366C5.04783 17.5 4.65533 17.5 4.41116 17.2558C4.16699 17.0117 4.16699 16.6192 4.16699 15.8333V11.7158Z"
            fill="#FF945D"
          />
          <path
            d="M2.5 10.3226C2.5 10.5442 2.5 10.6559 2.57 10.6901C2.64 10.7242 2.72833 10.6567 2.90333 10.5192L8.97667 5.79591C9.46833 5.41341 9.71417 5.22257 10 5.22257C10.2858 5.22257 10.5317 5.41424 11.0233 5.79591L17.0967 10.5192C17.2717 10.6559 17.36 10.7242 17.43 10.6901C17.5 10.6559 17.5 10.5442 17.5 10.3226V9.98174C17.5 9.58174 17.5 9.38174 17.415 9.20841C17.3308 9.03507 17.1725 8.91174 16.8567 8.66674L11.0233 4.12924C10.5317 3.74674 10.2858 3.55591 10 3.55591C9.71417 3.55591 9.46833 3.74757 8.97667 4.12924L3.14333 8.66674C2.8275 8.91174 2.66917 9.03507 2.585 9.20841C2.5 9.38174 2.5 9.58174 2.5 9.98174V10.3226ZM10.4167 12.5001H9.58333C9.14131 12.5001 8.71738 12.6757 8.40482 12.9882C8.09226 13.3008 7.91667 13.7247 7.91667 14.1667V17.3751C7.91667 17.4442 7.9725 17.5001 8.04167 17.5001H11.9583C11.9915 17.5001 12.0233 17.4869 12.0467 17.4635C12.0702 17.44 12.0833 17.4082 12.0833 17.3751V14.1667C12.0833 13.7247 11.9077 13.3008 11.5952 12.9882C11.2826 12.6757 10.8587 12.5001 10.4167 12.5001Z"
            fill="white"
          />
          <path
            d="M14.583 4.16675H13.7497C13.5196 4.16675 13.333 4.3533 13.333 4.58341V7.08341C13.333 7.31353 13.5196 7.50008 13.7497 7.50008H14.583C14.8131 7.50008 14.9997 7.31353 14.9997 7.08341V4.58341C14.9997 4.3533 14.8131 4.16675 14.583 4.16675Z"
            fill="white"
          />
        </svg>
        <p>Anasayfa</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardBreadcrumb",
};
</script>

<style lang="scss">
html {
  &.oly-sticky-active {
    .breadcrumb {
      top: 50px;
      background: $white;
    }
  }
}
.breadcrumb {
  display: flex;
  align-items: center;
  position: fixed;
  top: 80px;
  transition: $trns;
  background: $white;
  width: 100%;
  margin-bottom: 0 !important;
  border-bottom: 1px solid $light;
  box-shadow: 0 25px 40px -15px #eff0f2;
  z-index: 1000;
  * {
    //border-bottom: 0 !important;
  }
  ul {
    display: flex;
    gap: 14px;
    margin-bottom: 0;
    border-bottom: 0;
    padding-left: 0;
    padding: 15px 0 !important;
    align-content: center;
    width: fit-content;
    li {
      height: max-content;
      &:not(:last-child) {
        position: relative;
        top: -1px;
        &::before {
          content: "/";
          position: absolute;
          top: 56%;
          right: -9px;
          color: $gray;
          font-size: 12px;
          line-height: 16px;
          transform: translateY(-50%);
        }
      }
      a {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        &:hover {
          color: $gray;
        }
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .home-link {
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: $primary;
      padding: 12px 18px;
      height: 100%;
      transition: all 0.3s ease;
      p {
        font-size: 14px;
        font-weight: 600;
        color: $light;
        margin: 0;
      }
      &:hover {
        background-color: $secondary;
      }
      &:hover svg .colorful-path {
        transition: all 0.3s ease;
        fill: $secondary;
      }
    }
  }
}
</style>
