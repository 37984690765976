<template>
  <div class="row w-100 footer-menus">
    <div  v-for="section in footerStore.getters.sections" class="col-lg-3 col-md-6 list-menu mb-4 mb-lg-0">
      <p class="menu-header">{{ section.title }}</p>
      <ul class="menu">
        <li v-for="item in section.links" class="menu-item">
          <a :href="item.link" class="gray">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { footerStore } from "@/store/modules/footerStore";
import RingLoader from "vue-spinner/src/RingLoader.vue";

export default {
  name: "FooterMenus",
  computed: {
    footerStore() {
      return footerStore
    }
  },
  components : {RingLoader},
   setup(){
    const menus = ref({})
     footerStore.dispatch("getFooter")

    // menus.value =  {
    //   'Hakkımızda': [
    //     {
    //       title: getTranslation("footer","about_us"),
    //       target: "/about-us",
    //     },
    //     {
    //       title: getTranslation("footer","news"),
    //       target: "/news",
    //     },
    //     {
    //       title: getTranslation("footer","projects"),
    //       target: "/waterwell-projects",
    //     },
    //     {
    //       title: getTranslation("footer","accounts"),
    //       target: "/bank",
    //     },
    //     {
    //       title: getTranslation("footer","contact"),
    //       target: "/contact",
    //     },
    //   ],
    //   "Bilgi": [
    //     {
    //       title: getTranslation("footer","stories"),
    //       target: "/stories",
    //     },
    //     {
    //       title: getTranslation("footer","volunteer"),
    //       target: "/volunteer",
    //     },
    //     {
    //       title: getTranslation("footer","faq"),
    //       target: "/faqs",
    //     },
    //     {
    //       title: getTranslation("footer","costs"),
    //       target: "/costs",
    //     },
    //   ],
    //   'Projeler': [
    //     {
    //       title: getTranslation("footer","waterwell-projects"),
    //       target: "/waterwell-projects",
    //     },
    //     {
    //       title: getTranslation("footer","sacrifice"),
    //       target: "/sacrifice",
    //     },
    //     {
    //       title: getTranslation("footer","spring"),
    //       target: "/the-spring",
    //     },
    //   ],
    //   'Bağış': [
    //     {
    //       title: getTranslation("footer","lap"),
    //       target: "/project1",
    //     },
    //     {
    //       title: getTranslation("footer","fast-donate"),
    //       target: "/project2",
    //     },
    //   ],
    // }
    return {menus}
  }

};
</script>

<style lang="scss">
#footer .footer-wrapper .footer-body {
  .list-menu {
    p {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        a {
          text-decoration: none;
          transition: $trns;
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
</style>
