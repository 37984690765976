import { AuthGuardEnums } from "@/store/enums/AuthGuardEnum";

const payment = [
    {
        path: "/payment",
        name: "payment",
        component: () => import("@/views/Payment/Payment.vue"),
        meta: {
            layout: "PaymentLayout",
            header: "payment",
            guard: AuthGuardEnums.SYSTEM
        },
    },
    {
        path: "/payment/:step",
        name: "paymentStep",
        component: () => import("@/views/Payment/Payment.vue"),
        meta: {
            layout: "PaymentLayout",
            header: "payment",
            guard: AuthGuardEnums.SYSTEM
        },
    },
];

export default payment;
