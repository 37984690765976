<template>
  <div class="action-buttons">
    <button class="bt bt-transparent medium nav-text" :class="closeBtClass" @click="$emit('close')">
      {{ closeBtLabel }}
    </button>
    <button-el
      :isDisabled="disabled"
      :btn-class="openBtClass"
      :label="openBtLabel"
      :loading="openBtLoading"
      :icon="openBtIcon"
      @click="$emit('open')"
    />
  </div>
</template>

<script>
import ButtonEl from "@/components/UI/ButtonEl";
export default {
  name: "ActionButtons",
  components: { ButtonEl },
  props: {
    closeBtClass: {
      type: String,
      required: false,
      default: null,
    },
    closeBtLabel: {
      type: String,
      required: false,
      default: null,
    },
    openBtClass: {
      type: String,
      required: false,
      default: null,
    },
    openBtLoading: {
      type: Boolean,
      default: false,
    },

    openBtLabel: {
      type: String,
      required: false,
      default: null,
    },
    openBtIcon: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["open", "close"],
};
</script>

<style lang="scss">
.action-buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
