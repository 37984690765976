<template>
  <div class="social-n-select">
    <Social-list :links="socialLists" />
    <div class="select-wrapper">
      <!-- <multi-select
        v-model="selectedLang"
        track-by="name"
        label="name"
        :show-labels="false"
        :options="languageOption"
        :searchable="false"
        :allow-empty="false"
        @select="changeLang"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.name }}
        </template>
      </multi-select> -->
      <div class="btn-group dropup custom-dropup">
        <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          {{ activeLang }}
        </button>
        <ul class="dropdown-menu">
          <li v-for="lang in languageOption" @click="changeLang(lang.isoCode, lang.name)" class="dropdown-item">{{
            lang.name }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script >
import SocialList from "@/components/UI/SocialList.vue";
import { useI18n } from "vue-i18n";
import { ref } from 'vue';
export default {
  name: "SocialNSelect",
  components: { SocialList },
  setup() {
    const activeLang = ref(window.localStorage.getItem("lang") === "tr" ? 'Türkçe' : 'English');
    return {
      activeLang
    }
  },
  data() {
    return {
      selectedLang: {
        name: "Türkçe",
        isoCode: "tr",
      },
      languageOption: [
        {
          name: "Türkçe",
          isoCode: "tr",
        },
        {
          name: "English",
          isoCode: "en",
        },
      ],
      socialLists: [
        'facebook',
        'instagram'
      ]
    };
  },
  methods: {
    changeLang(val, name) {
      this.$i18n.locale = val;
      window.localStorage.setItem("lang", val);
      this.activeLang = name;
      location.reload()
    },
  },
};
</script>

<style lang="scss">
#footer .footer-wrapper .footer-body {
  .social-n-select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 554px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .select-wrapper {
      .custom-dropup {
        .dropdown-toggle {
          background-color: #fbfbfb;
          height: 50px !important;
          width: 96px;
          color: black;
          border: 1px solid #eff0f2;
          border-radius: 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          font-family: 'Source Sans Pro', sans-serif;

          &::after {
            position: relative;
            top: 4px;
            color: rgba($gray, .4);
            opacity: 0.4;
            right: -4px;
            content: "\f107";
            font-family: "Font Awesome 6 Free";
            border: none;
          }

          &.show {
            border: 1px solid $primary;
            color: $primary;

            &::after {
              content: '\f106';
              color: $primary;
            }
          }
        }

        .dropdown-menu {
          background-color: #fbfbfb;
          border: 1px solid #eff0f2;

          &.show {
            background: #FBFBFB;
            border: 1px solid #EFF0F2;
            box-shadow: 0px 25px 40px -15px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;
            border-radius: 0;
          }

          .dropdown-item {
            cursor: pointer;
            transition: $trns;
            padding: 0;

            &:not(:last-child) {
              border-bottom: 1px solid #EFF0F2;
              padding: 0 0 15px 0;
            }

            &:hover {
              background-color: transparent;
              color: $primary;
              transition: $trns;
            }
          }
        }
      }
    }
  }
}
</style>
