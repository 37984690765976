import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-100 d-flex justify-content-center",
  style: {"padding-top":"60px"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-center header" }
const _hoisted_4 = { class: "gray mb-0 text-center" }
const _hoisted_5 = { class: "code-inputs-wrapper" }
const _hoisted_6 = { class: "new-code" }
const _hoisted_7 = { class: "btn-text primary medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_code_inputs = _resolveComponent("code-inputs")!
  const _component_button_el = _resolveComponent("button-el")!
  const _component_el_modal = _resolveComponent("el-modal")!

  return (_openBlock(), _createBlock(_component_el_modal, {
    btnLabel: _ctx.$t('dashboard-start-spring'),
    "modal-wrapper-class": " code-inputs",
    "modal-size": "lg"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: require('@/assets/images/Verify_Email.png'),
          alt: ""
        }, null, 8, _hoisted_2)
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("dashboard-verf-title")), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("dashboard-verf-desc")), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_code_inputs, { onEndOfInputs: $setup.handleEnd }, null, 8, ["onEndOfInputs"]),
        _createVNode(_component_button_el, {
          onClick: $setup.nextStep,
          type: "button",
          label: _ctx.$t('verify-button'),
          icon: "shield",
          "btn-class": $setup.codeEntered ? 'bt-primary' : 'bt-dark',
          style: {"width":"100%"},
          "is-disabled": !$setup.codeEntered
        }, null, 8, ["onClick", "label", "btn-class", "is-disabled"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("dashboard-send-code")), 1)
      ])
    ]),
    _: 1
  }, 8, ["btnLabel"]))
}