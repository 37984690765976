<template class="off-menu-open">
  <meta name="robots" content="noindex" />

  <Header />
  <div class="root-wrapper" @click="">
    <slot></slot>
  </div>
  <Footer route="home" />
</template>

<script>
import Header from "@/components/Pages/Base/Header";
import Footer from "@/components/Pages/Base/Footer";
import { headerStore } from "@/store/modules/header";
export default {
  name: "BaseLayout",
  components: { Footer, Header },
  computed(){

  },
  watch: {
    "headerStore.getters.sideMenuActive": function () {
      if (headerStore.getters.sideMenuActive) {
        document.body.classList.add("off-menu-open");
        document.body.classList.remove("off-menu-close");
      } else {
        document.body.classList.remove("off-menu-open");
        document.body.classList.add("off-menu-close");
        document.querySelector(".collapse").classList.remove("show");
      }
    },
  },
};
</script>

<style lang="scss">
.off-menu-open {
  overflow: hidden;
  .root-wrapper,
  #header {
    overflow: hidden;
    animation: open 0.4s ease-in-out forwards;
  }
  .outer-nav-menu {
    z-index: 1000;
    transform: translateZ(0);
    button {
      box-shadow: 0px 8px 40px -12px rgba(243, 110, 42, 0.3);
    }
  }
}
.off-menu-close {
  .root-wrapper,
  #header {
    animation: close 0.4s ease-in-out forwards;
  }
}
.root-wrapper {
  backface-visibility: hidden;
}
@keyframes open {
  0% {
    transform: translateZ(0);
  }
  70% {
    transform: translate3d(240px, 0, 0);
  }
  100% {
    transform: translate3d(240px, 0, 0);
  }
}
@keyframes close {
  0% {
    transform: translate3d(240px, 0, 0);
  }
  100% {
    transform: translateZ(0);
  }
}
</style>
