<template>
  <div class="textarea-wrapper" :class="wrapperClass">
    <textarea v-model="value" :placeholder="placeholder" @input="$emit('update:modelValue', value)"></textarea>
    <inline-svg :src="require('@/assets/images/icons/message-captions.svg')"/>
  </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
export default {
  name: "TextareaWrapper",
  emits: ['update:modelValue'],
  props: {
    model: Object,
    wrapperClass: String,
    message: String,
    placeholder: String,
    modelValue: String,
  },
  setup(props, {emit}) {
    const value = ref(props.modelValue || '');
    onMounted(() => {
      watch(value, (val) => {
        emit('update:modelValue', val);
      });
    });
    return {
      value,
    }
  }
};
</script>

<style lang="scss">
.textarea-wrapper {
  position: relative;

  textarea {
    background: $lighter;
    border: 1px solid $light;
    width: 100%;
    height: 200px;
    resize: none;
    padding: 12px 36px 12px 16px;

    &:focus {
      border: 1px solid $primary;
      outline: none;
      background-color: white;

      & + svg {
        fill: $primary;
      }
    }
  }

  svg {
    position: absolute;
    top: 21px;
    right: 16px;
  }
}
</style>
