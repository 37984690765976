import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-100 d-flex justify-content-center",
  style: {"padding-top":"60px"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "second-font text-center header" }
const _hoisted_4 = { class: "gray mb-0 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_el = _resolveComponent("button-el")!
  const _component_el_modal = _resolveComponent("el-modal")!

  return (_openBlock(), _createBlock(_component_el_modal, {
    btnLabel: _ctx.$t('dashboard-start-spring'),
    "modal-wrapper-class": "spring-account-modal"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: $setup.popupData.img || require('@/assets/images/smile.png'),
          alt: ""
        }, null, 8, _hoisted_2)
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("h4", _hoisted_3, _toDisplayString($setup.popupData.title ||
          "İsteğin bizi çok mutlu etti ama önce hesabına giriş yapman gerekiyor :)"), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString($setup.popupData.description || " Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."), 1),
      _createVNode(_component_button_el, {
        "btn-class": "bt-primary",
        label: _ctx.$t('login-button'),
        icon: "user",
        onClick: $setup.routeLogin
      }, null, 8, ["label", "onClick"])
    ]),
    _: 1
  }, 8, ["btnLabel"]))
}