<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class=" donate-table-item-detail"
    @primaryClick="startSpring"
    modal-size="lg"
  >
    <template v-slot:body>
      <p class="gray text-center mb-0">
        {{ note }}
      </p>
    </template>
  </el-modal>
</template>

<script>
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  components: {
    ElModal,
    Checkbox,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const note = store.getters.getActiveModalData
    const startSpring = () => {
      // component will delete
    };
    return { startSpring, note };
  },
};
</script>

<style lang="scss" scoped></style>
