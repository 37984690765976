import { App } from "vue";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
//@ts-ignore
import JwtService from "@/services/JwtService";
import VueAxios from "vue-axios";
//@ts-ignore
import { TokenTypeEnum } from "@/store/network/TokenTypeEnum";
//@ts-ignore
import TokenModel from "@/store/network/TokenModel";
//@ts-ignore
import { Endpoints } from "@/core/network/Endpoints";
import i18n from "@/i18n";
import { useI18n } from "vue-i18n";
import { userStore } from "@/store/modules/UserStore";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  public static GuestClient: AxiosInstance;
  public static M2MClient: AxiosInstance;
  public static U2MClient: AxiosInstance;
  public static S2MClient: AxiosInstance;
  public static BaseClient: AxiosInstance;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
  
    const defaultConfig: AxiosRequestConfig = {
      headers: {
        Accept: "application/json",
        "X-localization": i18n.global.locale.value
      },
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: false
    };

    const userConfig: AxiosRequestConfig = {
      headers: {
        Accept: "application/json",
        "X-localization": i18n.global.locale.value
      },
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: false
    };

    const superVisorConfig: AxiosRequestConfig = {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-localization": i18n.global.locale.value
      },
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: false
    };

    const baseConfig: AxiosRequestConfig = {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-localization": i18n.global.locale.value
      },
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: false
    };

    ApiService.GuestClient = ApiService.vueInstance.axios.create(defaultConfig);
    ApiService.M2MClient = ApiService.vueInstance.axios.create(defaultConfig);
    ApiService.S2MClient = ApiService.vueInstance.axios.create(superVisorConfig);
    ApiService.U2MClient = ApiService.vueInstance.axios.create(userConfig);
    ApiService.BaseClient = ApiService.vueInstance.axios.create(baseConfig);

    ApiService.M2MClient.interceptors.request.use(
      async function(config) {
        //@ts-ignore
        const m2mToken: TokenModel = JwtService.getToken(TokenTypeEnum.M2M);
        if (!m2mToken.available()) {
          const data = await ApiService.GuestClient.post(
            Endpoints.USER_AUTH_M2M,
            {
              client_id: process.env.VUE_APP_LARAVEL_PASSPORT_CLIENT_ID,
              client_secret: process.env.VUE_APP_LARAVEL_PASSPORT_CLIENT_SECRET,
              scope: "*",
              grant_type: "client_credentials"
            }
          );
          m2mToken.token = data.data.access_token;
          JwtService.saveToken(TokenTypeEnum.M2M, m2mToken);
        }
        const u2mToken: TokenModel = JwtService.getToken(TokenTypeEnum.U2M);

        if(userStore.getters.getUser !== null && u2mToken.available()){
          //@ts-ignore
          config.headers["Authorization"] = `Bearer ${u2mToken.token}`;
        }
        //@ts-ignore
        config.headers["Authorization"] = `Bearer ${m2mToken.token}`;
        return config;
      },
      function(error: any) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    ApiService.BaseClient.interceptors.request.use(
      async function(config) {
        //@ts-ignore
        const m2mToken: TokenModel = JwtService.getToken(TokenTypeEnum.M2M);
        if (!m2mToken.available()) {
          const data = await ApiService.GuestClient.post(
            Endpoints.USER_AUTH_M2M,
            {
              client_id: process.env.VUE_APP_LARAVEL_PASSPORT_CLIENT_ID,
              client_secret: process.env.VUE_APP_LARAVEL_PASSPORT_CLIENT_SECRET,
              scope: "*",
              grant_type: "client_credentials"
            }
          );
          m2mToken.token = data.data.access_token;
          JwtService.saveToken(TokenTypeEnum.M2M, m2mToken);
        }
        //@ts-ignore
        config.headers["Authorization"] = `Bearer ${m2mToken.token}`;
        return config;
      },
      function(error: any) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    ApiService.U2MClient.interceptors.request.use(
      async function(config) {
        //@ts-ignore
        const u2mToken: TokenModel = JwtService.getToken(TokenTypeEnum.U2M);
        if (u2mToken.available()) {
          //@ts-ignore
          config.headers["Authorization"] = `Bearer ${u2mToken.token}`;
        }
        return config;
      },
      function(error: any) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    ApiService.S2MClient.interceptors.request.use(
      async function(config) {
        //@ts-ignore
        const u2mToken: TokenModel = JwtService.getToken(TokenTypeEnum.U2M);
        if (u2mToken.available()) {
          //@ts-ignore
          config.headers["Authorization"] = `Bearer ${u2mToken.token}`;
        }
        return config;
      },
      function(error: any) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }
}

export default ApiService;