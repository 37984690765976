<template>
  <svg
      :class="{ [size]: size, [type]: type, [bars]: bars, [font]: font }"
      class="chart"
      :viewBox="viewBox"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M 141.34 96.132 C 166.845 96.132 187.521 75.457 187.521 49.951 C 187.521 24.446 166.845 3.771 141.34 3.771 C 115.835 3.771 95.159 24.446 95.159 49.951 C 95.159 75.457 115.835 96.132 141.34 96.132 Z"
        stroke="#2F2F30"
        fill="none"
        stroke-width="6"
        v-if="bars === 'multiple'"
    />
    <path
        d="M 141.34 90.535 C 163.753 90.535 181.923 72.365 181.923 49.952 C 181.923 27.538 163.753 9.369 141.34 9.369 C 118.926 9.369 100.757 27.538 100.757 49.952 C 100.757 72.365 118.926 90.535 141.34 90.535 Z"
        stroke="#3F3F41"
        fill="none"
        stroke-width="5"
        v-if="bars === 'multiple'"
    />
    <path
        class="first-path"
        d="M 141.34 84.938 C 160.662 84.938 176.326 69.274 176.326 49.952 C 176.326 30.629 160.662 14.966 141.34 14.966 C 122.018 14.966 106.354 30.629 106.354 49.952 C 106.354 69.274 122.018 84.938 141.34 84.938 Z"
        stroke="#2F2F30"
        fill="none"
        stroke-width="5.8"
    />
    <path
        class="fill-bar"
        d="M 141.04 14.407 C 168.017 14.407 184.875 43.827 171.387 67.363 C 165.128 78.286 153.558 85.013 141.04 85.013 C 114.062 85.013 97.203 55.597 110.692 32.059 C 116.952 21.138 128.52 14.407 141.04 14.407 Z"
        stroke="#FF0000"
        fill="none"
        stroke-width="5"
        stroke-linecap="round"
        :stroke-dasharray="`${(220 * percent) / 100} 220`"
    />
    <text class="text" transform="matrix(1, 0, 0, 1, -5.082479, 8.39714)">
      <tspan style="font-family: inherit; font-weight: 700" x="145.539" y="43.543">{{ percent }}%</tspan>
      <tspan v-if="isCompleted" x="145.539" dy="2.3em" style="font-size: .3em;">Tamamlandı</tspan>
    </text>
  </svg>
</template>

<script>
export default {
  name: "Chart",
  props: {
    bars: String,
    size: String,
    type: String,
    font: String,
    percent: Number,
    isCompleted: Boolean,
    viewBox: {
      type: String,
      required: false,
      default: "90.998 -0.775 102 102",
    },
  },
};
</script>
<style lang="scss">
.chart {
  &.small {
    &.single {
      width: 65px;

      .text {
        font-size: 20px;
        transform: translate(-4px, 13px);

        tspan {
          color: #f3d32a;
        }
      }
    }

    &.multiple {
      width: 94px;

      text {
        line-height: 16px;
        transform: translate(-3px, 10px);
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  &.warning {
    &.single {
      .first-path {
        stroke: #eff0f2;
      }

      .fill-bar {
        stroke: #f3d32a;
        filter: drop-shadow(0px 0px 6px rgba(243, 211, 42, 0.4));
      }

      .text {
        fill: $warning;
        font-weight: bold;
        color: #f3d32a;
      }
    }

    &.multiple {
      path {
        &:nth-child(1) {
          stroke: rgba($warning, 0.2);
        }

        &:nth-child(2) {
          stroke: rgba($warning, 0.4);
        }

        &:nth-child(3) {
          stroke: rgba($warning, 0.5);
        }

        &:nth-child(4) {
          stroke: $warning;
          filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
        }
      }
    }
  }

  &.orange {
    path {
      &:first-child {
        stroke: rgba(#f8a87f, 0.12);
      }

      &:nth-child(2) {
        stroke: rgba(#f8a87f, 0.35);
      }

      &:nth-child(3) {
        stroke: rgba(#f8a87f, 0.75);
      }

      &:nth-child(4) {
        stroke: #fff;
        filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
      }
    }

    .text {
      fill: $white;
    }
  }

  &.orange-bar {
    &.single {
      .first-path {
        stroke: $light;
      }

      .fill-bar {
        stroke: $primary;
        filter: drop-shadow(0px 0px 6px rgba(243, 110, 42, 0.4));
      }

      .text {
        fill: $primary;
        font-weight: bold;
      }
    }

    path {
      &:first-child {
        stroke: rgba(#f8a87f, 0.12);
      }

      &:nth-child(2) {
        stroke: rgba(#f8a87f, 0.35);
      }

      &:nth-child(3) {
        stroke: rgba(#f8a87f, 0.75);
      }

      &:nth-child(4) {
        stroke: #fff;
      }
    }

    .text {
      fill: $white;
    }
  }

  &.light {
    path {
      &:first-child {
        stroke: rgba(#fff, .06);
      }

      &:nth-child(2) {
        stroke: rgba(#fff, 0.12);
      }

      &:nth-child(3) {
        stroke: rgba(#fff, 0.4);
      }

      &:nth-child(4) {
        stroke: #fff;
        filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
      }
    }

    .text {
      fill: $white;
    }
  }

  &.large {
    width: 235px;

    .text {
      font-size: 18px;
      transform: translate(-3px, 12px);

      tspan {
        color: #f3d32a;
      }
    }
  }

  &.zodiak {
    .text {
      font-family: Zodiak, $font-base-family;
    }
  }

  .text {
    fill: rgb(0, 0, 0);
    font-family: Arial, sans-serif;
    font-size: 9px;
    text-anchor: middle;
    white-space: pre;
  }
}
</style>
