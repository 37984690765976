import { AuthGuardEnums } from "@/store/enums/AuthGuardEnum";

const Base = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Base/Index.vue"),
    meta: {
      layout: "BaseLayout",
      title: "Ana sayfa",
      guard:AuthGuardEnums.SYSTEM
    },
  },
  {
    path: "/waterwell-projects",
    name: "waterwell",
    component: () => import("@/views/Base/Project1.vue"),
    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Su Kuyusu Projeleri"
    },
  },
  {
    path: "/waterwell-projects/detail/:id",
    name: "waterwell-detail",
    component: () => import("@/views/Base/Project1Detail.vue"),
    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Su Kuyusu Projeleri"
    },
  },
  {
    path: "/sacrifice",
    name: "sacrifice",
    component: () => import("@/views/Base/Project2.vue"),
    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Kurban Projeleri"
    },
  },
  {
    path: "/sacrifice/detail/:id",
    name: "sacrifice-detail",
    component: () => import("@/views/Base/Project2Detail.vue"),
    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Kurban Projeleri"
    },
  },
  {
    path: "/donate",
    name: "home-donate",
    component: () => import("@/views/Base/Donate.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Bağış"
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("@/views/Base/AboutUs.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Hakkımızda"
    },
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/Base/News.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Haberler"
    },
  },
  {
    path: "/news/detail/:slug",
    name: "news-detail",
    param: "slug",
    component: () => import("@/views/DetailPages/NewsDetail.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      multiplePages: true,
      title: "Haberler"
    },
  },
  {
    path: "/bank",
    name: "bank",
    component: () => import("@/views/Base/Bank.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Hesaplar"
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/Base/Contact.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "İletişim"
    },
  },
  {
    path: "/stories",
    name: "stories",
    component: () => import("@/views/Base/Stories.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Hikayelerimiz"
    },
  },
  {
    path: "/stories/detail/:id",
    name: "story-detail",
    component: () => import("@/views/DetailPages/StoryDetail.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      multiplePages: true,
      title: "Hikayelerimiz"
    },
  },
  {
    path: "/the-spring",
    name: "spring",
    component: () => import("@/views/Base/TheSpring.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      page: "spring",
      headerWrapper: "subPage",
      title: "The Spring"
    },
  },
  {
    path: "/the-spring/detail/:id",
    name: "spring-detail",
    component: () => import("@/views/Base/TheSpringDetail.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white  spring",
      headerWrapper: "subPage",
      title: "The Spring"
    },
  },
  {
    path: "/costs",
    name: "costs",
    component: () => import("@/views/Base/Costs.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Maaliyet Tablosu"
    },
  },
  {
    path: "/faqs",
    name: "faqs",
    component: () => import("@/views/Base/faqs.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "SSS"
    },
  },
  {
    path: "/volunteer",
    name: "volunteer",
    component: () => import("@/views/Base/Volunteer.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Gönüllü Ol"
    },
  },
  {
    path: "/standard-page",
    name: "standard-page",
    component: () => import("@/views/Base/StandardPage.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Blog"
    },
  },
  {
    path: "/why-water",
    name: "why-water",
    component: () => import("@/views/DetailPages/WhyWater.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Neden Su"
    },
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () => import("@/views/DetailPages/CookiePolicies.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      title: "Neden Su"
    },
  },
  {
    path: "/kvkk",
    name: "kvkk",
    component: () => import("@/views/DetailPages/KVKK.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
    },
  },
  {
    path: "/:slug",
    name: "kvkk",
    component: () => import("@/views/DetailPages/FixedPage.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
    },
  },
  {
    path: "/blogs/detail/:slug",
    name: "blogs-detail",
    param: "slug",
    component: () => import("@/views/DetailPages/BlogDetail.vue"),

    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      multiplePages: true,
      title: "Blog"
    },
  },
  {
    path: "/video/:slug",
    name: "donation-video",
    param: "slug",
    component: () => import("@/views/Base/VideoView.vue"),
    meta: {
      layout: "BaseLayout",
      header: "white",
      headerWrapper: "subPage",
      multiplePages: true,
      title: "Video"
    },
  },
];
export default Base;
