<template>
  <ul>
    <li v-for="item in itemsData" :key="item.id" class="nav-text gray">
      <router-link :to="'/' + item.slug">
        {{ item.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Copyright",
  data() {
    return {
      itemsData: [
        { id: 1, title: "KVKK", slug: "kvkk" },
        { id: 2, title: "Çerez Politikası", slug: "cookies" },
        { id: 3, title: "Kullanım Koşulları", slug: "terms-of-use" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  gap: 21px;
  text-transform: capitalize;
  li a {
    cursor: pointer;
    color: $gray;
    &:not(:last-child) {
      position: relative;
      &::before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -10px;
        border: 1px dashed rgba($gray, 0.2);
      }
    }
  }
}
</style>
