import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog",
  "aria-labelledby": "modalTitle",
  "aria-describedby": "modalDescription"
}
const _hoisted_2 = {
  class: "modal__header",
  id: "modalTitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_el = _resolveComponent("button-el")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-wrapper", [$props.modalWrapperClass, $setup.isMobile ? 'xs' : 'lg']])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("header", _hoisted_2, [
            _renderSlot(_ctx.$slots, "header", { class: "modal__header" })
          ]),
          _createVNode(_component_button_el, {
            onClick: $setup.close,
            onHandleClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
            "btn-class": "bt-white bt-close bold",
            icon: "xmark",
            label: _ctx.$t('dashboard-close-button'),
            "aria-label": "Close modal"
          }, null, 8, ["onClick", "label"]),
          _createElementVNode("section", {
            id: "modalDescription",
            class: _normalizeClass(["modal-body w-100", _ctx.$props.bodyClass])
          }, [
            _renderSlot(_ctx.$slots, "body")
          ], 2),
          ($props.btnClass)
            ? (_openBlock(), _createBlock(_component_button_el, {
                key: 0,
                disabled: $props.disabled,
                "btn-class": $props.btnClass,
                type: "button",
                icon: $props.btnIcon,
                label: $props.btnLabel,
                "aria-label": "Close modal",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('primaryClick')))
              }, null, 8, ["disabled", "btn-class", "icon", "label"]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ]),
    _: 3
  }))
}