export enum ModalEnum {
  DONATE_INFO_MODAL = "DonateInfoModal",
  DELETE_CAMPAIGN_MODAL = "CampaignDeleteModal",
  END_OF_CAMPAIGN_MODAL = "EndOfCampaignModal",
  MISSING_INFORMATION_MODAL = "MissingInformationModal",
  DONATE_NOTE_MODAL = "DonateNoteModal",
  DELETE_ACCOUNT_MODAL = "DeleteAccountModal",
  VERİFY_MODAL = "VerifyModal",
  VERIFIED_MODAL = "VerifiedModal",
  FAST_DONATE_MODAL = "FastDonateModal",
  THANKS_MODAL = "ThanksModal",
  QUİCK_WATERWELL_MODAL = "QuickDonateModal",
  SACRIFICE_MODAL = "SacrificeModal",
  SPRING_MODAL = "SpringModal",
  VALIDATION_ERROR = "ValidationErrorsModal",
  PAYMENTTHREED_MODAL  = 'PaymentThreeSecureModal'
}
