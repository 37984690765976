<template>
  <div class="footer-copy">
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <p class="dark copy">&copy; 2022 {{ $t('footer_rights_reserved') }}</p>
        <img :src="require('@/assets/images/kumsal-logo-dark.svg')" alt="" />
      </div>
      <div class="col-lg-7">
        <p class="span-text">
          {{ $t('footer-copy') }} Kayıtlı Adres: Hayra Vesile, Cevizli
          Mah. Tugayyolu Cad. No:12 Nuvo Dragos A-71 Maltepe / İstanbul
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCopy",
};
</script>

<style lang="scss">
#footer .footer-wrapper {
  .footer-copy {
    img {
      width: 100px;
    }

    .col-12 {
      @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 16px;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .copy {
      font-size: 16px;
    }

    p {
      line-height: 16px;
      margin-bottom: 0;
      color: #384456;

      &:last-child {
        opacity: 0.5;
      }
    }
  }
}</style>
