<template>
  <div class="header-nav-features order-1 order-lg-2">
    <div class="header-nav-feature">
      <router-link
        class="header-login-btn"
        :class="{ fullHeight: isLoggedIn }"
        :to="{ name: isLoggedIn ? 'dashboard' : 'auth' }"
      >
        {{
          userStore.getters.getUser !== null
            ? getTranslation("base_header", "my_panel")
            : getTranslation("base", "login")
        }}
      </router-link>
    </div>
    <div class="header-nav-feature">
      <button style="height: 65%" @click="goBasket">
        <inline-svg fill="white" :src="require('@/assets/images/icons/bag-shopping.svg')" />
      </button>
      <div class="basketCount" style="height: 35%">
        <p>{{ basketItems.length }}</p>
      </div>
    </div>
    <div class="header-nav-feature">
      <button @click="goToDonate">
        {{ getTranslation("base_header", "donate") }}
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { userStore } from "@/store/modules/UserStore";
import { getTranslation } from "../../../helpers/utilities";
import { ref, onMounted, watch } from "vue";
import { getToken } from "@/helpers/TokenActions";
import { getItems } from "@/helpers/BasketActions";
import { basketStore } from "@/store/modules/basketStore";
import { ModalEnum } from "@/store/enums/ModalEnum";
import { modalStore } from "@/store/modules/modals";

export default {
  name: "HeaderNavFeatures",
  methods: {
    getTranslation,
  },
  computed: {
    userStore() {
      return userStore;
    },
    basketStore() {
      return basketStore;
    },
  },
  data: () => ({
    donateModal: false,
  }),
  setup() {
    const router = useRouter();
    const store = modalStore;
    const isLoggedIn = userStore.getters.getUser !== null;
    const basketItems = ref([]);

    const goBasket = () => {
      router.push("/payment");
    };
    const goToDonate = () => {
      if (isLoggedIn) {
        router.push("/donate");
      } else {
        const payload = {
          activeModal: ModalEnum.THANKS_MODAL,
          data: "aaaa",
        };
        store.commit("setModal", payload);
      }
    };
    const getBasketItems = async () => {
      const sessionToken = getToken();
      const items = await getItems(sessionToken);
      basketItems.value = items.basket;
    };
    onMounted(() => {
      getBasketItems();
      watch(
        () => basketStore.state.basketItems,
        () => {
          getBasketItems();
        }
      );
    });
    return {
      goBasket,
      isLoggedIn,
      basketItems,
      goToDonate,
    };
  },
};
</script>

<style lang="scss">
html {
  #header .header-body {
    .header-nav-features {
      padding-left: 0;
      margin-left: 0;
      border-left: 0;
      .header-nav-feature {
        height: 100%;
        .header-login-btn {
          height: 100%;
        }
        .basketCount {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          p {
            color: #f36e2a;
            margin: 0;
            padding: 0;
            font-size: 12px;
            font-weight: 600;
          }
        }
        .fullHeight {
          height: 100% !important;
        }
        button,
        a {
          padding: 0 24px;
          height: 50%;
          display: flex;
          align-items: center;
          transition: 0.3s;
          text-transform: capitalize;
          &:hover {
            background: rgba(243, 110, 42, 0.12);
          }
          svg {
            transition: 0.3s;
          }
        }
        a {
          max-width: 100px;
        }

        @media screen and (max-width: 992px) {
          button {
            padding: 0 34px;
            @media screen and (max-width: 576px) {
              padding: 0 14px;
            }
          }
        }

        &:first-child {
          border-right: 1px solid rgba($white, 0.2);
          border-left: 1px solid rgba($white, 0.2);

          a:hover {
            color: $primary;
          }
        }

        &:nth-child(2) {
          button:hover {
            background: rgba(57, 170, 196, 0.08);

            svg {
              fill: $secondary;
            }
          }
        }

        &:nth-child(3) {
          padding: 0;

          button {
            background: $primary;
            color: $white;
            transition: 0.3s ease all;
            padding: 0 18px;
            white-space: nowrap;
            @media screen and (max-width: 774px) {
              padding: 0 10px;
            }
            &:hover {
              background: $secondary;
            }
          }
        }

        button {
          background: none;
          border: 0;
          height: 100%;
          font-size: 14px;
          line-height: 16px;

          svg {
            color: $white;
          }
        }

        @media screen and (max-width: 992px) {
          &:first-child {
            display: none;
          }
        }
      }

      @media screen and (max-width: 992px) {
        margin-right: 15px;
      }
    }
  }
  &.oly-sticky-active {
    #header {
      .header-body .header-nav-features {
        .basketCount {
          p {
            color: $gray;
          }
        }
        .header-nav-feature {
          &:first-child {
            border-right-color: $light;

            > a {
              color: $gray;

              &:hover {
                background: rgba(243, 110, 42, 0.12);
              }
            }
          }

          &:nth-child(2) {
            button {
              @media screen and (max-width: 992px) {
                padding: 0 34px;
              }

              &:hover {
                background: rgba(57, 170, 196, 0.12);
              }
            }

            svg {
              fill: $dark;
            }
          }
        }
      }
    }
  }
}
</style>
