export function createToken() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 15) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    const d = new Date()
    const nextWeek = new Date(d.setDate(d.getDate() + 7))
    window.localStorage.setItem('fake_token', result);
    window.localStorage.setItem('fake_token_expired', nextWeek.toString())
}

export function checkToken() {
    const token = window.localStorage.getItem('fake_token')
    const expireDate = new Date(window.localStorage.getItem('fake_token_expired') || '')
    return token !== null && expireDate > new Date()
}

export function getToken() {
    return window.localStorage.getItem('fake_token')
}
