<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class=" code-inputs"
    modal-size="lg"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img  style="height: 125px;" :src="require('@/assets/images/retry.png')" alt="" />

      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center header" v-if="!title">
        {{ $t("validation-error") }}
      </h4>
      <ul class="error-list">
        <li v-for="error in errors" class="error-item">{{error}}</li>
      </ul>


    </template>
  </el-modal>
</template>

<script lang="ts">
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox.vue";
import ActionButtons from "@/components/UI/ActionButtons.vue";
import CodeInputs from "@/components/Auth/CodeInputs.vue";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import { useRouter } from "vue-router";
import { ModalEnum } from "@/store/enums/ModalEnum";
import { ModalPayloadInterface } from "@/store/interfaces/ModalPayloadInterface";
import { useStore } from "vuex";
import i18n from "@/i18n";
import { modalStore } from "@/store/modules/modals";
export default {
  components: {
    ElModal,
    Checkbox,
    ActionButtons,
    CodeInputs,
    ButtonEl,
  },
  props:{
    showConfirmButton:{
      type:Boolean,
      default:true
    },

  },
  setup() {
    const store = modalStore;
    const router = useRouter();
    let errors = store.getters.getActiveModalData
    /*@ts-ignore*/
    errors = Object.values(errors.errors).map((i)=>i[0])
    return {errors };
  },
};
</script>

<style lang="scss" scoped>
.error-list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .error-item {
    color: #FF6161;
    padding: 10px 20px;
    list-style: decimal;

  }
}
</style>
