<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class="spring-modal sm"
  >
    <template v-slot:body>
      <div class="header">
        <h5 class="medium">Su krizini çözmede</h5>
        <p class="bold mb-0 gray span-text">Ayda ₺5.</p>
      </div>
      <div class="prices-wrapper">
        <price-inputs @selectedVal="catchAmount" :includeBtn="false" />
      </div>
      <div class="inputs-wrapper">
        <labeled-input-wrapper
          type="text"
          :placeholder="$t('form-name')"
          :label="$t('form-name')"
          icon="user"
          @update:inputVal="catchName"
        />
        <labeled-input-wrapper
          type="numeric"
          placeholder="Telefon"
          label="Telefon Numarası"
          icon="phone"
          @update:inputVal="catchPhone"
        />
        <button-el
          btn-class="bt-bg-primary w-100"
          :label="$t('support-text')"
          @click="nextStep"
          :isDisabled="!(amount > 0)"
        />
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox.vue";
import ActionButtons from "@/components/UI/ActionButtons.vue";
import CodeInputs from "@/components/Auth/CodeInputs.vue";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import LabeledInputWrapper from "@/components/UI/LabeledInputWrapper.vue";
import InputWrapper from "@/components/UI/InputWrapper.vue";
import PriceInputs from "@/components/Pages/Base/components/PriceInputs.vue";
import { modalStore } from "@/store/modules/modals";
import { useRoute } from "vue-router";
import { ref } from "vue";
import { addBasket } from "@/helpers/BasketActions";
import { ProjectTypeEnum } from "@/store/enums/ProjectTypeEnum";
import { userStore } from "@/store/modules/UserStore";

export default {
  components: {
    ElModal,
    Checkbox,
    ActionButtons,
    CodeInputs,
    ButtonEl,
    LabeledInputWrapper,
    InputWrapper,
    PriceInputs,
  },
  setup() {
    const route = useRoute();
    const id = route.path.split("/")[3];
    const store = modalStore;
    const name = ref();
    const phone = ref();
    const amount = ref();
    const catchName = (e: any) => {
      name.value = e;
    };
    const catchPhone = (e: any) => {
      phone.value = e;
    };

    const catchAmount = (e: any) => {
      amount.value = e;
    };
    const nextStep = async () => {
      const res = await addBasket(
        parseInt(id),
        ProjectTypeEnum.UserSpring,
        amount.value,
        userStore.getters.getUser !== null
          ? userStore.getters.getUser.id
          : null,
        name.value,
        phone.value
      );
      store.commit("closeModal");
    };
    return { nextStep, catchName, catchPhone, catchAmount, amount };
  },
};
</script>

<style lang="scss" scoped></style>
