<template>
  <div class="header-nav-menu outer-nav-menu">
    <img
      :src="require('@/assets/images/hayravesile-logo-dark.svg')"
      alt=""
      class="sidenav-logo"
    />
    <nav class="collapse">
      <NavPills :nav-items="navPills" />
    </nav>
  </div>
  <div class="header-placeholder"></div>
  <header id="header" class="white dashboard-header">
    <div class="header-body">
      <div class="container header-container">
        <div class="header-row">
          <header-logo route="dashboard" :nav-pills="navPills" />
          <div class="header-column">
            <div class="header-row">
              <header-nav :nav-pills="navPills" class="right-nav" />
              <div class="header-nav-features order-1 order-lg-2">
                <div class="header-nav-feature dropdown-handler">
                  <div class="name-wrapper">
                    <p class="span-text gray">
                      {{ $t("dashboard-hello-user") }}
                    </p>
                    <h4 class="dark name">{{ userStore.getters.getUser?.first_name }}</h4>
                  </div>

                    <img
                      :src="userStore.getters.getUser?.media?.avatar"
                      alt=""
                      class="user-avatar"
                    />
                  <dropdown
                    :items="dropPills"
                    :logoutItem="true"
                    v-show="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import HeaderLogo from "@/components/Bases/Header/HeaderLogo.vue";
import HeaderNav from "@/components/Bases/Header/HeaderNav.vue";
import Dropdown from "@/components/UI/Dropdown.vue";
import NavPills from "@/components/Bases/Header/NavPills";
import DashboardBreadcrumb from "@/components/Pages/Dashboard/DashboardBreadcrumb.vue";
import { useStore } from "vuex";
import ApiService from "@/services/ApiService";
import {Endpoint} from "@/core/network/Endpoint";
import {Endpoints} from "@/core/network/Endpoints";
import { userStore } from "../../../store/modules/UserStore";

export default {
  name: "DashboardHeader",
  computed: {
    userStore() {
      return userStore
    }
  },
  components: {
    Dropdown,
    HeaderNav,
    HeaderLogo,
    DashboardBreadcrumb,
    NavPills,
  },
  setup() {
    const store = useStore();
    const sidebarActive = ref(store.getters.sideMenuActive);
    const user = ref();
    onMounted(async () => {
      const res = await ApiService.U2MClient.get(Endpoint.prepare(Endpoints.USER_PROFILE));
      user.value = res.data.data;
    })
    return {
      sidebarActive,
      user,
    };
  },
  data() {
    return {
      isSticky: false,
      dropdownActive: false,
      navPills: [
        {
          nav: this.$t("dashboard-nav-donates"),
          routerLink: "/dashboard/donate",
        },
        {
          nav: this.$t("dashboard-nav-water"),
          routerLink: "/dashboard/water",
        },
        {
          nav: this.$t("dashboard-nav-waterweels"),
          routerLink: "/dashboard/named-water",
        },
        {
          nav: this.$t("dashboard-nav-my-campaigns"),
          routerLink: "/dashboard/my-campaigns",
        },
      ],
      dropPills: [
        {
          nav: this.$t("profile"),
          routerLink: "/profile/personal-data",
        },
        {
          nav: this.$t("campagin"),
          routerLink: "/dashboard/my-campaigns",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      scrollY > 1
        ? document.documentElement.classList.add("oly-sticky-active")
        : document.documentElement.classList.remove("oly-sticky-active");
    });
    document
      .querySelector(".dropdown-handler")
      .addEventListener("click", () => {
        this.dropdownActive = !this.dropdownActive;
      });
  },
};
</script>

<style lang="scss">
html {
  .header-placeholder {
    width: 100%;
    height: 135px;
    transition: $trns;
    opacity: 0;
  }

  #header {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
    img.user-avatar {
      margin: 0;
      padding: 0;
      border-radius: 100%;
    }
    &.white {
      background: $white;
      border-bottom: 1px solid $light;
    }

    &.dashboard-header {
      .header-nav-features .header-nav-feature {
        &:first-child {
          padding-right: 0;
          border-right: 0;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .dropdown-list {
          .drop-item {
            &:not(:last-child) {
              border-bottom: 1px solid #eff0f2;
            }

            a {
              padding: 0;
            }

            &:hover {
              a {
                background: transparent;
              }
            }

            &:last-child {
              a {
                &:hover {
                  color: $danger;
                }
              }
            }

          }
        }
      }
    }

    .header-body {
      height: 80px;
    }

    .header-container {
      height: 100%;
    }

    .header-nav-features {
      .header-nav-feature {
        gap: 10px;

        &.dropdown-handler {
          transition: $trns;
          cursor: pointer;
          .dropdown-list {
            transition: $trns;
          }
          &:hover {
            transition: $trns;
            .dropdown-list {
              transition: $trns;
              display: flex !important;
            }
          }
        }

        &:first-child {
          img {
            max-width: 54px;
            transition: $trns;
          }
        }

        .name-wrapper {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          p,
          h4 {
            margin-bottom: 0;
          }
          @media screen and (max-width: 500px) {
            h4 {
              width: 70px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        @media screen and (max-width: 992px) {
          &.dropdown-handler {
            display: flex;
          }
        }
      }
    }
  }

  &.oly-sticky-active {
    .header-placeholder {
      height: 105px;
    }

    #header {
      .header-body {
        height: 50px;
      }

      .header-nav-features {
        .header-nav-feature:first-child img {
          max-width: 40px;
        }
      }
    }
  }
}
</style>
