import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-nav h-100" }
const _hoisted_2 = { class: "header-nav-menu" }
const _hoisted_3 = { class: "collapse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavPills = _resolveComponent("NavPills")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createVNode(_component_NavPills, { "nav-items": $props.navPills }, null, 8, ["nav-items"])
      ])
    ])
  ]))
}