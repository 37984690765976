const Dashboard = [
    {
        path: "/dashboard",
        name: "dashboard",
        redirect: {name: "panel"},
        children: [
            {
                path: "panel",
                name: "panel",
                component: () => import("@/views/Dashboard/Panel.vue"),
                meta: {
                    layout: "DashboardLayout",
                    //You have to check how to change this based on language
                    title: "Panelim",
                    guard: 1
                },
            },
            {
                path: "donate",
                name: "donate",
                component: () => import("@/views/Dashboard/Donate.vue"),
                meta: {
                    layout: "DashboardLayout",
                    title: "Bağışlarım",
                    guard: 1
                },
            },
            {
                path: "water",
                name: "water",
                component: () => import("@/views/Dashboard/Water.vue"),
                meta: {
                    layout: "DashboardLayout",
                    title: "Kaç Kişiyi Suya Kavuşturdum",
                    guard: 1
                },
            },
            {
                path: "named-water",
                name: "named-water",
                component: () => import("@/views/Dashboard/NamedWater.vue"),
                meta: {
                    layout: "DashboardLayout",
                    title: "İsim Verdiğim Su Kuyuları",
                    guard: 1
                },
            },
            {
                path: "my-campaigns",
                name: "my-campaigns",
                component: () => import("@/views/Dashboard/Campaigns/MyCampaigns.vue"),
                props: true,
                params: {
                    test: "hello",
                },
                meta: {
                    layout: "DashboardLayout",
                    title: "Kampanyalarim",
                    guard: 1
                },
            },
            {
                path: "new-campaigns",
                name: "new-campaigns",
                component: () => import("@/views/Dashboard/Campaigns/NewCampaigns.vue"),
                meta: {
                    layout: "DashboardLayout",
                    title: "Kampanya Başlat",
                    guard: 1
                },
            },
            {
                path: "campaign/:id",
                name: "campaign",
                component: () => import("@/views/Dashboard/Campaigns/Campaign.vue"),
                meta: {
                    layout: "DashboardLayout",
                    title: "Kampanyalarim",
                    guard: 1

                },
                redirect: "campaign/:id/details",
                children: [
                    {
                        name: "campaign-details",
                        path: "details",
                        component: () =>
                            import("@/views/Dashboard/Campaigns/CampaignDetails.vue"),
                    },
                    {
                        name: "campaign-target",
                        path: "target",
                        component: () =>
                            import("@/views/Dashboard/Campaigns/CampaignTarget.vue"),
                    },
                    {
                        name: "campaign-donates",
                        path: "donates",
                        component: () =>
                            import("@/views/Dashboard/Campaigns/CampaignDonates.vue"),
                    },
                    {
                        name: "campaign-options",
                        path: "options",
                        component: () =>
                            import("@/views/Dashboard/Campaigns/CampaignOptions.vue"),
                    },
                ],
            },
        ],
        guard: 1
    },
    {
        path: "/terms-of-use",
        name: "terms-of-use",
        component: () => import("@/views/DetailPages/TermsOfUse.vue"),
        meta: {
            layout: "BaseLayout",
            header: "white",
            headerWrapper: "subPage",
            multiplePages: false,
            title: "Kampanya Başlat",
        },
    },

];
export default Dashboard;
