//Auth
export const SEND_AUTH_CODE = "SEND_AUTH_CODE";
export const RESET = "RESET";
export const LOGIN = "LOGIN";
export const NEW_MEMBER = "NEW_MEMBER";
export const UPDATE_PHONE = "UPDATE_PHONE";
export const RESET_CODE = "RESET_CODE";
export const UPDATE_CODE = "UPDATE_CODE";
export const VALID_EMAIL = "VALID_EMAIL";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const DONATE_MODAL = "DONATE_MODAL";
export const SACRIFICE_MODAL = "SACRIFICE_MODAL";
export const CODE_INPUTS_MODAL = "CODE_INPUTS_MODAL";
export const MY_CAMPAIGN_MODAL = "MY_CAMPAIGN_MODAL";
export const NAMED_WATER_DONATE_MODAL = "NAMED_WATER_DONATE_MODAL";
export const SPRING_DONATE_MODAL = "SPRING_DONATE_MODAL";
export const CAMPAIGN_TARGET_MODAL = "CAMPAIGN_TARGET_MODAL";
export const VALIDATION_DONE_MODAL = "VALIDATION_DONE_MODAL";
export const CAMPAIGN_DETAILS_MODAL = "CAMPAIGN_DETAILS_MODAL";
export const WATER_WELL_QUICK_DONATE = "WATER_WELL_QUICK_DONATE";
export const PROFILE_OPTIONS_MODAL = "PROFILE_OPTIONS_MODAL";
export const DONATE_TABLE_ITEM_DETAIL = "DONATE_TABLE_ITEM_DETAIL";
export const START_SPRING_ACCOUNT_MODAL = "START_SPRING_ACCOUNT_MODAL";