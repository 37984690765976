<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class=" code-inputs"
    modal-size="lg"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/Verify_Email.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center header">{{ $t("dashboard-verf-title") }}</h4>
      <p class="gray mb-0 text-center">
        {{ $t("dashboard-verf-desc") }}
      </p>
      <div class="code-inputs-wrapper">
        <code-inputs @endOfInputs="handleEnd" />
        <button-el
          @click="nextStep"
          type="button"
          :label="$t('verify-button')"
          icon="shield"
          :btn-class="codeEntered ? 'bt-primary' : 'bt-dark'"
          style="width: 100%"
          :is-disabled="!codeEntered"
        />
        <!--Needed to provide width here cause it's not working in this file's styles, even if it's not scoped-->
      </div>
      <div class="new-code">
        <span class="btn-text primary medium">{{
          $t("dashboard-send-code")
        }}</span>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { ref } from "vue";
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox.vue";
import ActionButtons from "@/components/UI/ActionButtons.vue";
import CodeInputs from "@/components/Auth/CodeInputs.vue";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import { useRouter } from "vue-router";
import { ModalEnum } from "@/store/enums/ModalEnum";
import { ModalPayloadInterface } from "@/store/interfaces/ModalPayloadInterface";
import { useStore } from "vuex";
export default {
  components: {
    ElModal,
    Checkbox,
    ActionButtons,
    CodeInputs,
    ButtonEl,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const codeEntered = ref(false);
    const handleEnd = () => {
      codeEntered.value = true;
    };
    const nextStep = () => {
      store.commit("closeModal");
      const payload: ModalPayloadInterface = {
        activeModal: ModalEnum.VERIFIED_MODAL,
        data: "aaaaa",
      };
      store.commit("setModal", payload);
    };
    return { nextStep, codeEntered, handleEnd };
  },
};
</script>

<style lang="scss" scoped></style>
