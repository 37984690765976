import TokenModel from "@/store/network/TokenModel";
import JwtService from "@/services/JwtService";
import {TokenTypeEnum} from "@/store/network/TokenTypeEnum";
import {userStore} from "@/store/modules/UserStore";
import router from "@/router"
import {AuthGuardEnums} from "@/store/enums/AuthGuardEnum";
export default class AuthMiddleware {
    public guard: AuthGuardEnums;
    private tokenAvailable = false;

    constructor(guard: AuthGuardEnums) {
        this.guard = guard;
    }
    public async protect(to:any) {
        const token: TokenModel = JwtService.getToken(TokenTypeEnum.U2M);
        this.tokenAvailable = token.available();
        let can = false;
        let isLoggedInAuth = await this.auth();

        switch (this.guard) {
            case AuthGuardEnums.GUEST:
                can = await this.guest();
                if (!can) {
                    await router.push("/dashboard/panel");
                }
                break;
            case AuthGuardEnums.AUTH:
                if (!isLoggedInAuth) {
                    await router.push("/auth/login");
                }
                break;
            case AuthGuardEnums.SYSTEM:
                // can = this.system();
                break;
            default:
                break;
        }

    }
    private async guest() {
        return !(await this.auth());
    }
    private async auth() {
        const store = userStore;
        if (store.getters.getUser !== null) {
            return true;
        }
        if (this.tokenAvailable) {
            try {
                await store.dispatch('showUser');
                return store.getters.getUser !== null;
            } catch (e) {
                return false;
            }
        }
        return false;
    }
}