
export class Endpoint {
    static prepare(endpoint: string, params?: object) {
        let fullEndpoint: string = endpoint;
        if(params) {
            Object.keys(params).forEach((key) => {
                //@ts-ignore
                fullEndpoint = endpoint.replace(`{${key}}`, params[key].toString());
            });
        }

        return fullEndpoint;
    }
}
