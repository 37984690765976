<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class=" campaign-detail"
    @primaryClick="startSpring"
    modal-size="sm"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/trash.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center second-font header">
        {{ $t("dashboard-modal-title") }}
      </h4>
      <p class="gray mb-0 text-center">
        {{ $t("dashboard-modal-desc") }}
      </p>
      <action-buttons
        @open="confirm"
        @close="cancel"
        :close-bt-label="$t('dashboard-modal-continue')"
        open-bt-class="bt-bg-danger"
        open-bt-icon="trash"
        :open-bt-label="$t('dashboard-modal-remove')"
      />
    </template>
  </el-modal>
</template>

<script>
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox";
import ActionButtons from "@/components/UI/ActionButtons";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ApiService from "@/services/ApiService";
import { Endpoint } from "@/core/network/Endpoint";
import { Endpoints } from "@/core/network/Endpoints";
import { modalStore } from "@/store/modules/modals";
import Swal from "sweetalert2";
export default {
  components: {
    ElModal,
    Checkbox,
    ActionButtons,
  },
  setup() {
    const store = modalStore;
    const router = useRouter();
    const id = store.getters.getActiveModalData;
    const confirm = async () => {
      // component will delete
      const res = await ApiService.U2MClient.put(
        Endpoint.prepare(Endpoints.USER_SPRINGS_DELETE, { id: id })
      );
      if (res.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Teşekkürler",
          text: "Kampanya silme talebiniz iletildi.",
        });
        cancel();
      }
    };
    const cancel = () => {
      store.commit("closeModal");
    };
    return { confirm, cancel };
  },
};
</script>

<style lang="scss" scoped></style>
