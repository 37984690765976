export enum ProjectTypeEnum {
    Sacrifice= 1,
    WaterWell ,
    UserSpring,
}

export function getProjectLabel(type: ProjectTypeEnum) {
    switch (type) {
        case ProjectTypeEnum.Sacrifice:
            return 'Kurban'
        case ProjectTypeEnum.WaterWell:
            return 'Su Kuyusu'
        case ProjectTypeEnum.UserSpring:
            return 'The Spring'
        default:
            break
    }


}