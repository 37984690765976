<template>
  <div class="form-wrapper">
    <div class="price-area">
      <div v-for="price in prices" class="item" :class="terminating ? ['disabled'] : []"
        @click="changeSelected($event, price)">
        ₺{{ price }}
      </div>
    </div>
    <input-wrapper v-model="customPrice" :is-disabled="terminating" @input="customInput" type="numeric"
      placeholder="Farklı miktar (Min 5.)" />
    <div v-if="terminate" @click="terminateProject" class="terminate-project " :class="terminating ? ['bg-warning'] : []">
      <div>
        <inline-svg :src="require('@/assets/images/icons/circle-check-2.svg')" />
        <p class="btn-text gray mb-0 medium">{{ $t("end-project") }}</p>
      </div>
      <p class="btn-text dark mb-0 text-left bold">{{ amount }}</p>
    </div>
  </div>
</template>

<script>
import InputWrapper from "@/components/UI/InputWrapper";
import ButtonEl from "@/components/UI/ButtonEl";
export default {
  name: "PriceInputs",
  components: { ButtonEl, InputWrapper },
  props: {
    prices: {
      type: Array,
      required: false,
      default: ["50", "100", "150", "200"],
    },
    terminate: {
      type: Boolean,
      required: false,
      default: false,
    },
    includeBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    amount: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    selected: {
      item: undefined,
    },
    customPrice: "",
    terminating: false
  }),
  methods: {
    changeSelected(e, price) {
      if (this.terminating) return;
      this.selected.item = e.target;
      document
        .querySelectorAll(".item")
        .forEach((item) => item.classList.remove("selected"));
      this.selected.item.classList.add("selected");
      this.$emit("selectedVal", price);
    },
    terminateProject() {
      this.terminating = !this.terminating
      if (this.terminating) {
        this.$emit('selectedVal', this.$props.amount)
      } else {
        this.$emit('selectedVal', 1)
      }
    },
    customInput(e) {
      document
        .querySelectorAll(".item")
        .forEach((item) => item.classList.remove("selected"));
      this.selected.item = "custom price";
      this.$emit("selectedVal", e.target.value);
    },
  },
};
</script>

<style lang="scss">
.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 30px;
  @include dashed-border();

  .price-area {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    gap: 5px;

    .item {
      border: 1px solid #eff0f2;
      backdrop-filter: blur(6px);
      background: #fbfbfb;
      width: calc(100% / 2 - 2.5px);
      height: 58px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      cursor: pointer;
      transition: $trns;
      font-size: 16px;
      line-height: 34px;
      font-weight: 400;
      color: rgba($gray, .75);

      &:hover,
      &.selected {
        transition: $trns;
        background: $primary;
        color: $white;
      }

      &.disabled {
        background: rgba(108, 117, 125, 0.05) !important;
      }
    }
  }

  .input-wrapper input {
    font-size: 16px;
    line-height: 34px;
  }
}
</style>
