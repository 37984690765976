
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {
    AuthStoreActions,
    AuthStoreMutations,
} from "@/store/enums/AuthStoreEnum";
import ApiService from "@/services/ApiService";
import {Endpoint} from "@/core/network/Endpoint";
import {Endpoints} from "@/core/network/Endpoints";

@Module
class AuthModule extends VuexModule {
    phone: string | null = null;

    get getPhone(): string | null {
        return this.phone;
    }
    @Mutation
    [AuthStoreMutations.SET_PHONE](phone: string) {
        this.phone = phone;
    }

    @Action
    async [AuthStoreActions.LOGIN](credentials: string) {
        try {
            const res = await ApiService.M2MClient.post(Endpoint.prepare(Endpoints.USER_AUTH_LOGIN), {
                firebase_token: credentials
            })
            return res;
        } catch (error) {
            console.error(error)
        }
    }

    async [AuthStoreActions.SEND_OTP](phone: string | null) {
        try {
            return true;
        } catch (_) {
            return false;
        }
    }

}

export default AuthModule;
