import {createStore} from "vuex";
import {config} from "vuex-module-decorators";
import login from "@/store/modules/login";
import AuthModule from "@/store/modules/AuthModule";

config.rawError = true;

const store = createStore({
    modules: {
        AuthModule,
        login,
    },
});

export default store