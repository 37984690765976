import ApiService from "@/services/ApiService";
import {Endpoints} from "@/core/network/Endpoints";
import apiService from "@/services/ApiService";
import {checkToken, createToken, getToken} from "@/helpers/TokenActions";
import Swal from "sweetalert2";
import { basketStore } from "@/store/modules/basketStore";
import { userStore } from "@/store/modules/UserStore";

export async function addBasket (projectId?: number, projectType?: number, amount?:number, userId?:number, name?: any, number?:any, shareVal?:any) {
    const existToken:boolean = checkToken()
    try {
        let userid = userStore.getters.getUser !== null ? userStore.getters.getUser.id : null;
        if (existToken) {
            const res = await (userStore.getters.getUser !== null ? ApiService.U2MClient : ApiService.M2MClient).post(Endpoints.BASKETS, {
                user_id: userid,
                session_token: getToken(),
                name: name ? name : 'anonymous',
                phone_number: number,
                amount: amount,
                basket_able_type: projectType,
                basket_able_id: projectId,
                share_value: shareVal ? shareVal : 1
            })
        } else {
            createToken()
            const res = await ApiService.M2MClient.post(Endpoints.BASKETS, {
                user_id: userId,
                session_token: getToken(),
                name: name ? name : 'anonymous',
                phone_number: number,
                amount: amount,
                basket_able_type: projectType,
                basket_able_id: projectId,
                share_value: shareVal ? shareVal : 1
            })
        }

        basketStore.dispatch("getBasketItems")
        Swal.fire({
            icon: "success",
            title: "Teşekkürler",
            text: "Bağış başarılı bir şekilde sepete eklendi.",
        });
        return true

    } catch (e) {
        Swal.fire({
            icon: "error",
            title: "Opss...",
            text: "Sepete eklerken bir sorun oluştu. Lütfen tekrar deneyin",
        });
        return false
    }
}

export async function getItems (token: number|string) {
    const res = await apiService.M2MClient.get(Endpoints.BASKETS, {
        params: {
            session_token: token
        }
    })
    const data:any = res.data
    return data
}