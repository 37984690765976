<template>
<el-modal modal-wrapper-class="lg">
  <template v-slot:body class="p-0">
    <iframe style="width: 100%; min-height: 40vh;" id="paymentModalIframe" :srcdoc="data.payload"></iframe>
  </template>

</el-modal>
</template>

<script>
import ElModal from "@/components/UI/Modal.vue";
import {modalStore} from "@/store/modules/modals";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import { ModalEnum } from "@/store/enums/ModalEnum";

export default {
  name: "PaymentThreeSecureModal",
  components : { ButtonEl, ElModal},
  emits: ['success-payment','error-payment'],
  setup(){
    const data = modalStore.getters.getActiveModalData


    return {
      data
    }
  }
};
</script>

<style scoped>

</style>