import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
export interface State {
    yellowHeaderVisible: Boolean,
    sideMenuActive: Boolean,
}
export const key: InjectionKey<Store<State>> = Symbol();

export const headerStore = createStore<State>({
    state: () => ({
        yellowHeaderVisible: true,
        sideMenuActive: false,
    }),
    mutations: {
        hideYellowHeader(state: any) {
            state.yellowHeaderVisible = false;
        },
        // call this on click on menu bars
        toggleSideMenu(state: any) {
            if (!state.sideMenuActive) {
                document.body.classList.add("off-menu-open");
                document.body.classList.remove("off-menu-close");
            } else {
                document.body.classList.remove("off-menu-open");
                document.body.classList.add("off-menu-close");
                document.querySelector(".collapse")?.classList.remove("show");
            }
            state.sideMenuActive = !state.sideMenuActive;
        },
        closeSideMenu(state: any) {
            state.sideMenuActive = false;
        },
    },
    getters: {
        yellowHeaderVisible(state: any) {
            return state.yellowHeaderVisible;
        },
        // this should go to app or body
        sideMenuActive(state: any) {
            return state.sideMenuActive;
        },
    },
});

export function useStore() {
    return baseUseStore(key);
}