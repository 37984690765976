<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class=" campaign-detail"
    @primaryClick="startSpring"
    modal-size="lg"
  >
    <template v-slot:header>
      <div
        class="w-100 d-flex justify-content-center"
        style="padding-top: 60px"
      >
        <img :src="require('@/assets/images/check.png')" alt="" />
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center second-font header">
        {{ $t("dashboard-end-camp-modal-title") }}
      </h4>
      <p class="gray mb-0 text-center">
        {{ $t("dashboard-end-camp-modal-desc") }}
      </p>
      <action-buttons
        @open="handleConfirm"
        @close="cancel"
        :close-bt-label="$t('dashboard-modal-continue')"
        :open-bt-label="$t('dashboard-modal-end')"
        open-bt-class="bt-success"
        open-bt-icon="badge-check"
      />
    </template>
  </el-modal>
</template>

<script>
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox";
import ActionButtons from "@/components/UI/ActionButtons";
import { useRouter } from "vue-router";
import { modalStore } from "@/store/modules/modals";
import ApiService from "@/services/ApiService";
import { Endpoint } from "@/core/network/Endpoint";
import { Endpoints } from "@/core/network/Endpoints";
import Swal from "sweetalert2";

export default {
  components: {
    ElModal,
    Checkbox,
    ActionButtons,
  },
  setup() {
    const store = modalStore;
    const router = useRouter();
    const id = store.getters.getActiveModalData;
    const handleConfirm = async () => {
      // component will delete
      const res = await ApiService.U2MClient.put(
        Endpoint.prepare(Endpoints.USER_SPRINGS_END, { id: id })
      );
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Teşekkürler",
          text: "Kampanyanız sonlandırıldı.",
        });
        store.commit("closeModal");
      }
      console.log(res);
    };
    const cancel = () => {
      store.commit("closeModal");
    };
    return { handleConfirm, cancel };
  },
};
</script>

<style lang="scss" scoped></style>
