<template>
  <div :class="`input-wrapper ${wrapperClass} ${focus}`">
    <input
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @onChange="$emit('change')"
        @keydown="isNum"
        :value="modelValue"
        :type="type"
        :placeholder="placeholder"
        :maxlength="max"
        :id="id"
        :disabled="isDisabled"
    />
    <inline-svg
        v-if="icon"
        :src="require(`@/assets/images/icons/${icon}.svg`)"
    />
    <span class="error-msg">
      <slot name="errorMsg"></slot>
    </span>
  </div>
</template>

<script>
import {isNumber} from "@/helpers/utilities";

export default {
  name: "InputWrapper",
  components: {},
  props: {
    wrapperClass: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    isDisabled: Boolean,
    modelValue: String,
    max: String,
    id: String,
    focus: String,
    inputVal: String,
  },
  emits: ["update:modelValue", "focus", "blur"],
  methods: {
    isNum(e) {
      if (this.type === "numeric") {
        isNumber(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
  transition: $trns;

  .error-msg {
    position: absolute;
    width: 100%;
    bottom: -28px;
    font-size: 14px;
    color: red;
    font-family: inherit;
  }

  &.valid {
    border-color: $primary;
  }

  &.error {
    margin-bottom: 20px;

    input {
      border-color: red !important;
    }
  }

  &.socialLinkInput {
    &::before {
      content: "https://";
      position: absolute;
      padding: 16px 0;
      left: 16px;
      color: $gray;
      font-weight: 600;
    }

    input {
      padding-left: 80px;
    }
  }

  input {
    padding: 12px 36px 12px 16px;
    width: 100%;
    border: 1px solid $light;
    height: 58px;
    background: $lighter;
    color: $gray;
    transition: $trns;

    &:focus,
    &.valid {
      outline: none;
      border: 1px solid $primary;
      color: $dark;
      background: white;

      & + svg {
        fill: $primary;
      }
    }

    &:disabled {
      background: #eff0f2;
      border: 1px solid #eff0f2;
      cursor: not-allowed;
      user-select: none;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    transition: $trns;
  }
}
</style>
