import { ModalPayloadInterface } from "../interfaces/ModalPayloadInterface";

// const modals = {
//   state: {
//     activeModal: null,
//     data: null,
//     callback: null,
//     fallback: null,
//   },
//   mutations: {
//     closeModal(state: any) {
//       state.activeModal = null;
//       state.data = null;
//       state.callback = null;
//       state.fallback = null;
//     },
//     setModal(state: any, payload: ModalPayloadInterface) {
//       state.activeModal = payload.activeModal;
//       state.data = payload.data;
//       state.callback = payload.callback;
//       state.fallback = payload.fallback;
//     },
//   },
//   getters: {
//     getActiveModal(state: any) {
//       return state.activeModal;
//     },
//     getActiveModalData(state: any) {
//       return state.data;
//     }
//   },
// };
//
// export default modals;

import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

export interface State {
  activeModal: any,
  data?: any,
  callback?: any,
  fallback?: any
  autoClose? : any
}

export const key: InjectionKey<Store<State>> = Symbol()

export const modalStore = createStore<State>({
  state: {
    activeModal: null,
    data: null,
    callback: null,
    fallback: null,
    autoClose: false,
  },
  mutations: {
    closeModal(state: any) {
      state.activeModal = null;
      state.data = null;
      state.callback = null;
      state.fallback = null;
    },
    setModal(state: any, payload: ModalPayloadInterface) {
      state.activeModal = payload.activeModal;
      state.data = payload.data;
      state.callback = payload.callback;
      state.fallback = payload.fallback;
      state.autoClose = payload.autoClose;

    },
  },
  getters: {
    getActiveModal(state: any) {
      return state.activeModal;
    },
    getActiveModalData(state: any) {
      return state.data;
    },
    getAutoClose(state:any){
      return state.autoClose
    }
  },
})

// define your own `useStore` composition function
export function useStore () {
  return baseUseStore(key)
}