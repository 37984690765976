<template>
  <div class="code-inputs">
    <div class="inputs">
      <input
          v-for="(n, index) in pinCount"
          @input="next($event, index)"
          @keydown="deleteControl($event, index)"
          v-model="inputs[index]"
          type="text"
          :key="n"
      />
    </div>
  </div>
</template>

<script>
import {isNumber} from "@/helpers/utilities";
import {UPDATE_CODE} from "@/store/constants";
import { useStore } from "vuex";

export default {
  name: "CodeInputs",
  data() {
    return {
      inputs: [],
      pinCount: 6,
      store: useStore()
    };
  },
  methods: {
    next(e, index) {
      if (!e.data) return;
      if (this.inputs[index].length === 2) {
        this.inputs[index] = this.inputs[index][1];
      }

      e.target.classList.add("valid", "bg-white");
      this.finalCode += e.target.value;
      this.store.commit(UPDATE_CODE, this.generateCode());
      if (e.target.nextElementSibling) {
        e.target.nextElementSibling.focus();
      } else {
          this.$emit("catchCode", this.generateCode())
      }
    },
    isNumber,
    deleteControl(e, index) {
      isNumber(e);
      if (e.keyCode === 8) {
        e.target.classList.remove("valid");
        if (e.target.previousElementSibling && this.inputs[index] === "") {
          e.target.previousElementSibling.focus();
        }
        this.inputs[index] = "";
        e.target.value = "";


        return false;
      }
    },
    generateCode() {
      return this.inputs.join("");
    },
  },
  mounted() {
    for (let i = 0; i < this.pinCount; i++) {
      this.inputs.push("");
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  .code-inputs .inputs input {
    @media screen and (max-width: 600px) {
      width: 40px;
      height: 40px;
    }
  }
}

.inputs {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  margin-top: 30px;
  justify-content: space-evenly;
  @media screen and (max-width: 774px) {
    gap: 5px;
  }

  input {
    background: $lighter;
    border: 1px solid $light;
    width: 56px;
    height: 58px;
    text-align: center;

    &:focus,
    &.valid {
      outline: none;
      border-color: $primary;
    }

    &.bg-white {
      background-color: white;
    }
  }

  button {
    &.valid {
      background-color: white;
    }
  }
}
</style>
