const login = [
  {
    path: "/auth",
    name: "auth",
    redirect: { name: "login" },
    meta: {
      layout: "AuthLayout",
      guard: 0
    },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/Auth/Login.vue"),
      },
      {
        path: "otp",
        name: "otp",
        component: () => import("@/views/Auth/OTP.vue"),
        meta: {
          requiredAuth: true,
          validState: "auth-step",
          fallbackRoute: "/auth/login",
          guard: 0
        },
      },
      {
        path: "newMember",
        name: "newMember",
        component: () => import("@/views/Auth/NewMember.vue"),
        meta: {
          requiredAuth: true,
          validState: "sign-up",
          fallbackRoute: "/auth/login",
          guard: 1
        },
      },
      {
        path: "error",
        name: "error",
        component: () =>
          import("@/components/Pages/Auth/IncorrectInputWrapper.vue"),
        meta: {
          requiredAuth: true,
          validState: "sign-up",
          fallbackRoute: "/auth/login",
          guard: 0
        },
      },
    ],
  },
];

export default login;
