import ApiService from "@/services/ApiService";
import { Endpoint } from "@/core/network/Endpoint";
import { Endpoints } from "@/core/network/Endpoints";

import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import apiService from "@/services/ApiService";
import { getToken } from "@/helpers/TokenActions";

export interface State {
  translations?: any;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const translationStore = createStore<State>({
  state: {
    translations: null

  },
  getters: {
    translations(state: any) {
      return state.translations;
    }

  },
  mutations: {
    setTranslations(state: any, payload: any) {
      state.translations = payload;
    }

  },
  actions: {
    async getTranslations(state: any) {
      try {
        const res = await apiService.M2MClient.post(Endpoints.TRANSLATIONS);

        translationStore.commit("setTranslations", res.data.data);
      } catch (e) {
        console.log(e);
      }

    }

  }
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}