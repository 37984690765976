import { Endpoints } from "@/core/network/Endpoints";

import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import apiService from "@/services/ApiService";

export interface State {
  sections?: any;
  loading: Boolean;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const footerStore = createStore<State>({
  state: {
    sections: null,
    loading: true

  },
  getters: {
    sections(state: any) {
      return state.sections;
    },
    loading(state: any) {
      return state.loading;
    }
  },
  mutations: {
    setSections(state: any, payload: any) {
      state.sections = payload;
    },
    setLoading(state: any, payload: Boolean) {
      state.loading = payload;
    }

  },
  actions: {
    async getFooter(state: any) {
      footerStore.commit("setLoading", true);
      try {
        const res = await apiService.M2MClient.get(Endpoints.FOOTER);
        footerStore.commit("setSections", res.data.data);
        footerStore.commit("setLoading", false);
      } catch (e) {
      }

    }

  }
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}