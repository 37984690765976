import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import login from "./routes/login";
import base from "@/router/routes/base";
import dashboard from "@/router/routes/dashboard";
import profile from "@/router/routes/profile";
import payment from "@/router/routes/payment";
import AuthMiddleware from "@/router/middlewares/AuthMiddleware";
import {AuthGuardEnums} from "@/store/enums/AuthGuardEnum";
import TranslationMiddleware from "@/router/middlewares/TranslationMiddleware";

const routes = [...base, ...login, ...dashboard, ...profile, ...payment];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach( async(to: any, from: any) => {
      if (to.meta.guard !== null) {
        const middleware = new AuthMiddleware(to.meta.guard as AuthGuardEnums);
        await middleware.protect(to);
      }
  window.document.title = to.meta && to.meta.title ? to.meta.title+' -  Hayravesile ' : 'Hayravesile';

  await new TranslationMiddleware().protect()
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
});

export default router;
