export default {
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "campagin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagin"])},
  "com-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication Line"])},
  "contact-breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options You Can Reach Us"])},
  "month-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can donate for as little as $5 per month or donate monthly You can join our community, The Spring."])},
  "joim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Can in solving the water crisis."])},
  "mdl-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are very happy with your request, but first you need to log in to your account :)"])},
  "mdl-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla\n        in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "end-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay all"])},
  "organised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organised"])},
  "per-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per person"])},
  "form-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "form-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Address"])},
  "form-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "form-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "form-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
  "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
  "donate-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Note"])},
  "select-camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select campaign"])},
  "given-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given name"])},
  "cancel-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
  "share-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring QR Code"])},
  "together-we-can": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together we can end the water crisis"])},
  "etap-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26. Stage Awaits Your Support"])},
  "etap-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "verf-waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting Verification"])},
  "send-verf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Again for Approval"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
  "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good to See You"])},
  "login-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick login with phone number verification."])},
  "login-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "otp-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number Verification"])},
  "otp-subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter code sent to ", _interpolate(_named("phoneNumber")), " phone number ."])},
  "wrong-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! It looks like you entered the wrong code."])},
  "wrong-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Code Again"])},
  "time-expired-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! It seems you entered the wrong code."])},
  "time-expired-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If Code Again"])},
  "register-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's Get to Know You Better"])},
  "register-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add your personal information, no one is used without your permission to wonder."])},
  "register-pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Picture"])},
  "register-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "register-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Surname"])},
  "register-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "register-kvkk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I approve the KVKK text."])},
  "register-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "kvkk-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KVKK"])},
  "cookie-policy-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Policy"])},
  "terms-of-use-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
  "nav-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Projects"])},
  "nav-about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know Us"])},
  "nav-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "nav-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Accounts"])},
  "nav-the-spring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Spring"])},
  "nav-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "nav-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "nav-donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
  "nav-projects-drop-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Well"])},
  "nav-projects-drop-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["victim"])},
  "site-topbar-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every donation brings new hope."])},
  "slider-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together <br /> We Will Change the World"])},
  "slider-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "slider-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Donate"])},
  "slider-card-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "support-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "see-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Details"])},
  "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
  "slider-card-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your monthly donation of ₺40 can provide clean water to 12 people every year."])},
  "general-badge-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT WE HAVE ACHIEVEMENT"])},
  "numbers-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["771 million people lack basic access to clean and safe drinking water. We are on a mission to change that."])},
  "numbers-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "numbers-item-1-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["91,414"])},
  "numbers-item-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water projects funded"])},
  "numbers-item-f2-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14,762,215"])},
  "numbers-item-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man served"])},
  "numbers-item-3-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29"])},
  "numbers-item-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Country"])},
  "numbers-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know Us"])},
  "home-about-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT WE HAVE ACHIEVEMENT"])},
  "home-about-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["771 million people lack basic access to clean and safe drinking water. We are on a mission to change that."])},
  "home-about-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "home-about-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Now"])},
  "our-informercial-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KNOW CLOSELY"])},
  "our-informercial-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" This is a true story"])},
  "our-informercial-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "badge-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TheSpring"])},
  "the-spring-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring members bring clean water to 45,197 people every month."])},
  "the-spring-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "the-spring-see-al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Different Springs"])},
  "the-spring-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring members are trying to make an impact and end the water crisis. are committed monthly donors."])},
  "the-spring-bottom-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start a Spring with You"])},
  "the-spring-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor.Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "home-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["771 million people lack basic access to clean and safe drinking water. We are on a mission to change that."])},
  "home-card-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "home-card-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean Makes Water"])},
  "waterwell-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE HOPE"])},
  "waterwell-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together, the foundation of poverty We impacted the lives of more than 200 million vulnerable children by tackling their causes."])},
  "waterwell-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "waterwell-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name the Water Well"])},
  "waterwell-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together We Will Change the World"])},
  "blog-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STORIES"])},
  "blog-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen to Us Once"])},
  "blog-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "about-us-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together We Will Change the World"])},
  "about-us-breadcrumb-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "about-us-breadcrumb-scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll down"])},
  "about-card-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVERYTHING IS IN THE MIDDLE"])},
  "about-card-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have the word - and more"])},
  "about-card-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "about-card-item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Prove Every Project"])},
  "about-card-item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Are An Open Book"])},
  "about-card-item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Goes to the Field"])},
  "about-us-map-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE ARE WE"])},
  "about-us-map-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together We've Been So Much"])},
  "about-us-map-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "about-us-counter-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget\n                fringilla in. Quam mauris in tellus viverra hac diam vel, tellus\n                tortor."])},
  "bank-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
  "bank-breadcrumb-scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll down"])},
  "bank-branch-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BRANCH NAME"])},
  "bank-accound-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOUNT NAME"])},
  "bank-swift-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWIFT CODE"])},
  "bank-accound-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOUNT TYPE"])},
  "bank-account-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HESAP NO"])},
  "bank-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EK NO"])},
  "bank-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "contact-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Contact Information"])},
  "contact-social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Social Media Accounts"])},
  "contact-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
  "contact-form-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEA MESSAGE"])},
  "contact-form-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How Can We Help You?"])},
  "contact-form-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "donate-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
  "news-breadcrumb-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "news-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Piece Everything For Smile"])},
  "news-breadcrumb-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "news-page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "sacrifice-detail-breadcrumb-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacrifice Detail"])},
  "sacrifice-detail-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything for a Piece of Smile"])},
  "sacrifice-detail-breadcrumb-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "sacrifice-detail-input1-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Share"])},
  "sacrifice-detail-input2-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different Amount (Min. 5)"])},
  "sacrifice-detail-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "waterwell-breadcrumb-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterwell"])},
  "waterwell-breadcrumb-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anything for a Smile"])},
  "waterwell-breadcrumb-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "waterwell-page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor. Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "other-project-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT'S ON"])},
  "other-project-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Other Projects"])},
  "other-project-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "donate-summary-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate Summary"])},
  "donate-summary-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Your Account Details"])},
  "payment-info-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
  "payment-info-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Your Account Details"])},
  "donate-success-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate Completed"])},
  "donate-success-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Your Account Details"])},
  "payment-basket-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Donation Cart"])},
  "payment-clear-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "payment-total-donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Donation"])},
  "continue-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "login-register-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In Sign Up"])},
  "payment-card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Card Number"])},
  "payment-card-mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AA"])},
  "payment-card-yy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY"])},
  "payment-card-cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
  "payment-card-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and Surname on Card"])},
  "payment-accept-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the terms of use."])},
  "payment-save-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save card and make it default."])},
  "donate-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
  "donate-success-title-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for being a new hope :)"])},
  "donate-success-desc-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "donate-success-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get More Help"])},
  "costs-table-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COUNTRY"])},
  "costs-table-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" TYPE"])},
  "costs-table-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISTANCE"])},
  "costs-table-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSON"])},
  "costs-table-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COST"])},
  "volunteer-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
  "volunteer-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "volunteer-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
  "volunteer-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Address"])},
  "volunteer-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "volunteer-gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gender"])},
  "volunteer-birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "volunteer-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
  "volunteer-district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
  "volunteer-education-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
  "volunteer-ed-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level of Education"])},
  "volunteer-institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
  "volunteer-episode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
  "volunteer-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Status"])},
  "volunteer-langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Known Languages"])},
  "volunteer-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
  "volunteer-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your note"])},
  "volunteer-span-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will get back to you as soon as possible."])},
  "volunteer-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteer"])},
  "dashboard-frequency-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "dashboard-frequency-monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "dashboard-frequency-yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "dashboard-nav-donates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My donations"])},
  "dashboard-nav-water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many K I Got Water"])},
  "dashboard-nav-waterweels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Wells I Named"])},
  "dashboard-nav-my-campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My campaigns"])},
  "dashboard-hello-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello,"])},
  "dashboard-my-panel-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My panel"])},
  "dashboard-total-donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Donation"])},
  "dashboard-total-waterwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Well"])},
  "dashboard-total-waterwells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People Got Water"])},
  "dashboard-total-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminated Project"])},
  "dashboard-total-victim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacrifice"])},
  "dashboard-total-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed projects"])},
  "dashboard-total-how-many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many people ı got water"])},
  "dashboard-my-donates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My donations"])},
  "dashboard-donate-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Type"])},
  "dashboard-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "dashboard-donate-type-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate Type"])},
  "dashboard-campagin-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Status:"])},
  "dashboard-campagin-redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Campaign"])},
  "dashboard-waterwell-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How Many People Have I Got Watered"])},
  "dashboard-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Water Wells I Name"])},
  "dashboard-table-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "dashboard-table-project-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name"])},
  "dashboard-table-donate-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation Amount"])},
  "dashboard-table-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of People"])},
  "dashboard-my-campagins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Campaigns"])},
  "dashboard-start-spring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring Start"])},
  "dashboard-the-spring-badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TheSpring"])},
  "dashboard-campagin-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring members bring clean water to 45,197 people every month."])},
  "dashboard-campagin-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "dashboard-kvkk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I approve the KVKK text."])},
  "dashboard-spring-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TheSpring Launch Conditions"])},
  "dashboard-start-campagin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Start Campaign"])},
  "dashboard-personal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
  "dashboard-bring-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring My Profile Information"])},
  "dashboard-form-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "dashboard-form-surname ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
  "dashboard-campagin-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Information"])},
  "dashboard-visible-campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicly Visible Campaign Name"])},
  "dashboard-visible-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Visible Image"])},
  "dashboard-special-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Day"])},
  "dashboard-campagin-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Name"])},
  "dashboard-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "dashboard-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "dashboard-about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "dashboard-desc-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can edit the description field if you wish."])},
  "dashboard-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
  "dashboard-set-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Your Goal"])},
  "dashboard-set-target-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Spring you will start is limited to 3 months. It will be terminated by the system after the specified time."])},
  "dashboard-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Limitation"])},
  "dashboard-socail-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Accounts"])},
  "dashboard-my-campagin-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current campaign start request is under review . When approved, it will be listed here."])},
  "dashboard-waitings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Approval Denied"])},
  "dashboard-application-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Date"])},
  "dashboard-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "dashboard-tab-pers-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
  "dashboard-tab-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
  "dashboard-tab-donates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations"])},
  "dashboard-tab-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "dashboard-cancel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "dashboard-update-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request an Update"])},
  "dashboard-close-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "dashboard-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send campaign deletion request"])},
  "dashboard-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone when approved by admin."])},
  "dashboard-modal-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I will continue"])},
  "dashboard-modal-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a Campaign to Delete"])},
  "dashboard-update-alert ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current update request is under evaluation, you cannot make any changes until the request is finalized."])},
  "dashboard-camp-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Status"])},
  "dashboard-camp-status-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End current campaign"])},
  "dashboard-end-campagin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Campaign"])},
  "dashboard-end-camp-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to end the current campaign?"])},
  "dashboard-end-camp-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If approved, no action will be taken within this campaign ."])},
  "dashboard-modal-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, End"])},
  "dashboard-remove-camp-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want the campaign I started to be deleted."])},
  "dashboard-remove-camp-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Campaign Deletion Request Find"])},
  "dashboard-up-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "dashboard-account-remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send account deletion request"])},
  "dashboard-account-remove-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone once confirmed by admin ."])},
  "dashboard-account-remove-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Account Deletion"])},
  "verify-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "dashboard-total-dont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Total Donations"])},
  "dashboard-regular-dont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Regular Donations"])},
  "dashboard-verf-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify email address"])},
  "dashboard-verf-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the code sent to the email address. If you cannot view the verification email, please check your spam box."])},
  "dashboard-send-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Code"])},
  "dashboard-verf-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the code sent to the email address."])},
  "dashboard-verf-success-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for the verification."])},
  "dashboard-verf-success-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you allow notifications, you will be able to receive notifications and developments via email."])},
  "dashboard-verf-success-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "dashboard-notification-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Preferences"])},
  "dashboard-accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
  "dashboard-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
  "dashboard-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "dashboard-remove-account-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want all my personal information and account deleted."])},
  "dashboard-remove-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Account Deletion"])},
  "dashboard-my-cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Saved Cards "])},
  "dashboard-card-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "dashboard-card-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Payment Method"])},
  "dashboard-card-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "dashboard-card-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "dashboard-card-set-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Default"])},
  "dashboard-card-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "dashboard-card-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete your registered card?"])},
  "dashboard-card-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
  "dashboard-card-modal-accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Delete My Saved Card"])},
  "dashboard-card-default-modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops :("])},
  "dashboard-card-default-modal-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The card you are trying to delete is set by default. You should change your default card before deleting."])},
  "dashboard-card-default-modal-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "dashboard-set-default-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make it my default card"])},
  "dashboard-add-card-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Card"])},
  "all-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Projects"])},
  "home-all-projects-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "listen-from-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir de bizden dinleyin"])},
  "footer-about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "footer-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "footer-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "footer-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "footer-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "footer-faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
  "footer-volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be Volunteer"])},
  "footer-stories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stories"])},
  "footer-costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterwell costs table"])},
  "footer-sacrifice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacrifice"])},
  "footer-waterwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterwell"])},
  "footer-lap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xth lap waterwell"])},
  "footer-fast-donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast Donate"])},
  "footer-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayra Vesile is a Turkish registered charity 9128441 and Türkiye is a registered company."])},
  "footer_rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])},
  "add-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add name (not required)"])},
  "add-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add phone (not required)"])},
  "donate-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate the name of the person you want to donate."])},
  "whats-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's new"])},
  "project2-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla in. Quam mauris in tellus viverra hac diam vel, tellus tortor."])},
  "waterwell": {
    "for_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalf Myself"])},
    "for_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalf Someone"])},
    "make_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
    "person_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of People"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "fast_donate_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add whoever you want to donate on behalf of. "])},
    "get_profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Profile Informations"])},
    "donater_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify the type of person you want to donate to."])},
    "add_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Name"])},
    "add_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Phone"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])}
  }
}