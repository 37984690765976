<template>
  <div class="header-column outer-wrapper">
    <div class="header-row left-nav">
      <div class="header-nav h-100">
        <button
            class="bars btn header-btn-collapse-nav"
            data-bs-toggle="collapse"
            data-bs-target=".outer-nav-menu nav"
            @click="handleSideMenuButton"
        >
          <inline-svg
              :src="require('@/assets/images/icons/bars-white.svg')"
          ></inline-svg>
          <inline-svg
              :src="require('@/assets/images/icons/bars-dark.svg')"
          ></inline-svg>
        </button>
      </div>
    </div>
    <div class="header-row">
      <div class="header-logo">
        <router-link :to="{name: route}">
          <img
              alt="Hayra vesile"
              :src="require('@/assets/images/hayravesile-logo.svg')"
          />
          <img
              alt="Hayra vesile"
              :src="require('@/assets/images/hayravesile-logo-dark.svg')"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from "./HeaderNav.vue";
import NavPills from "@/components/Bases/Header/NavPills";
import {headerStore} from "@/store/modules/header";

export default {
  name: "Header-logo",

  components: {NavPills, HeaderNav},
  props: {
    navPills: Array,
    route: String,
  },
  setup(props) {
    const route = props.route;
    const handleSideMenuButton = () => {
      headerStore.commit("toggleSideMenu")
    }
    return {
      route,
      handleSideMenuButton
    }
  }
};
</script>

<style lang="scss">
html {
  #header {
    .outer-wrapper {
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      padding: 0;
      border: 0;

      .header-row {
        &:first-child button {
          padding: 0 34px 0 15px;
        }

        &:last-child {
          padding: 0 13px;
          border: 1px solid rgba($white, 0.2);
          border-top: 0;
          border-bottom: 0;
        }

        .logo {
          color: white;
          letter-spacing: -0.06em;
          font-size: 24px;
          line-height: 24px;
          transition: .3s ease all;
          @media screen and (max-width: 554px) {
            font-size: 18px;
          }
        }
      }

      @media screen and (min-width: 992px) {
        .left-nav {
          display: none;
        }
      }
    }

    img {
      &:first-child {
        opacity: 1;
        display: block;
      }

      &:last-child {
        opacity: 0;
        display: none;
      }
    }

    &.white {
      .outer-wrapper {
        .header-row:last-child {
          border-color: $light;
        }
      }

      img {
        &:first-child {
          opacity: 0;
          display: none;
        }

        &:last-child {
          opacity: 1 !important;
          display: block !important;
        }
      }
    }
  }

  &.oly-sticky-active {
    #header {
      .outer-wrapper {
        .header-row:last-child {
          border-color: $light;
        }

        .header-logo {
          margin: 0;
        }

      }

      img {
        &:first-child {
          opacity: 0;
          display: none;
        }

        &:last-child {
          opacity: 1 !important;
          display: block !important;
        }
      }
    }
  }
}
</style>
