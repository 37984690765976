<template>
  <div class="input-outer-wrapper" :class="wrapperClass">
    <label class="span-text gray" for="name">{{ label }}</label>
    <textarea-wrapper v-if="type === 'textarea'" />
      <custom-drop-down v-else-if="type === 'dropdown'" @select-action="selectAction" :options="options"></custom-drop-down>
    <input-wrapper
      v-else
      :placeholder="placeholder"
      :type="type"
      :icon="icon"
      :id="id"
      :focus="focus"
      v-model="value"
      @update:inputVal="$emit('update:inputVal', $event)"
    />
  </div>
</template>

<script>
import InputWrapper from "@/components/UI/InputWrapper";
import TextareaWrapper from "@/components/UI/TextareaWrapper";
import CustomDropDown from "@/components/Form/CustomDropDown.vue";
import {onMounted, ref, watch} from "vue";
export default {
  name: "LabeledInputWrapper",
  components: {CustomDropDown, TextareaWrapper, InputWrapper },
  props: {
    wrapperClass: String,
    placeholder: String,
    label: String,
    focus: String,
    type: String,
    icon: String,
    id: String,
    model: Object,
    inputVal: String,
    modalValue: Object,
      options:Object,
  },
    setup(props, { emit }) {
      const value = ref();
      onMounted(() => {
          watch(value, (val) => {
              emit('update:inputVal', val);
          })
      })
        const selectAction = (e) => {
          emit('selectAction', e)
        }
        return {
          selectAction,
            value
        }
    }
};
</script>

<style lang="scss">
.input-outer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    width: max-content;
    font-weight: 700;
  }
}
</style>
