<template>
   <meta name="robots" content="noindex" />
  <SubPageHeader />
  <div class="payment-layout">
    <div class="container">
      <slot></slot>
    </div>
  </div>
  <Footer class="dashboard" />
</template>

<script>
import Footer from "@/components/Pages/Base/Footer";
import SubPageHeader from "@/components/Bases/Header/SubPageHeader";
export default {
  name: "PaymentLayout",
  components: { SubPageHeader, Footer },
};
</script>

<style scoped></style>
