<template>
  <div
    class="drop-wrapper"
    :style="{ width: `${width}px`, height: `${height}px` }"
    :class="isFluid === 1 ? 'fluid' : ''"
  >
    <multi-select2 v-model="dropValue" :can-deselect="false" :options="options" @select="listenSelect" />
    <inline-svg fill="#F36E2A" :src="require(`@/assets/images/icons/down.svg`)" width="10" height="12" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CustomDropDown",
  components: {},
  props: ["width", "options", "isFluid", "height", "dropValue"],
  setup(props, { emit }) {
    let dropValue = ref(props.dropValue || (props.options?.length > 0 ? ref<any>(props.options[0].value) : 0));

    const listenSelect = (e: any): void => {
      emit("selectAction", e);
    };
    return { dropValue, listenSelect };
  },
});
</script>

<style lang="scss"></style>
