<template>
  <div id="footer">
    <div class="container">
      <div class="footer-wrapper">
        <div class="footer-header">
          <router-link to="/">
            <img :src="require('@/assets/images/hayravesile-logo-dark.svg')" alt="" />
          </router-link>
          <div class="contact-number">
            <div>
              <span>{{ $t('com-line') }}</span>
              <h3 class="dark"><a href="https://wa.me/+905494586868">0 (549) 458 68 68</a></h3>
            </div>
            <img :src="require('@/assets/images/icons/whatsapp.svg')" alt="" />
          </div>
        </div>
        <div class="footer-body">
          <footer-menus />
          <social-n-select />
        </div>
        <footer-copy />
      </div>
    </div>
  </div>
</template>

<script>
import SocialList from "@/components/UI/SocialList";
import FooterCopy from "@/components/Bases/Footer/FooterCopy";
import SocialNSelect from "@/components/Bases/Footer/SocialNSelect";
import FooterMenus from "@/components/Bases/Footer/FooterMenus";
import { onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Endpoints } from "@/core/network/Endpoints";

export default {
  name: "Footer",
  components: { FooterMenus, SocialNSelect, FooterCopy, SocialList },
  props: {
    route: String,
  },
  setup() {
    const phone = ref("");
    onMounted(async () => {
      const res = await ApiService.M2MClient.get(Endpoints.CONTACT)
      console.log(res);
      phone.value = res?.data?.data[1]?.value
    })
    return {
      phone,
    }
  }
};
</script>

<style lang="scss">
.multiselect,
.multiselect>* {
  width: 120px;
}

.multiselect__option--highlight {
  background: $primary !important;
}

#footer {
  padding: 30px 0;
  background: $lighter;

  //removed cause my campaign page
  //margin-top: 120px;
  &.dashboard {
    background: $lighter;

    .footer-wrapper {

      .footer-header .contact-number,
      .footer-body .footer-menus {
        display: none;
      }
    }
  }

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 61px;

    .footer-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px 0;
      @include dashed-border(-30px);

      .contact-number {
        display: flex;
        gap: 15px;

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          span {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.03em;
            color: #384456;
          }

          h3 {
            transition: $trns;

            a {
              font-family: $font-base-family;
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 34px;
              letter-spacing: -0.03em;
              color: #000000;
              transition: $trns;

              &:hover {
                transition: $trns;
                color: #128C7E;
              }
            }
          }
        }
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        .contact-number {
          align-items: center;

          div {
            align-items: flex-start;
          }

          img {
            order: -1;
          }
        }
      }
    }

    .footer-body {
      display: flex;
      flex-direction: column;
      gap: 30px;
      @include dashed-border(-30px);

      p {}
    }
  }
}
</style>
